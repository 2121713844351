@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin truncate($width) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $width;
}

@mixin center-align(){
  display: flex;
  align-items: center;
  justify-content: center;
}
