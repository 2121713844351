@import '../../styles/color.scss';

.range-slider-container{
  width: 100%;
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  .MuiSlider-thumb{
    .MuiSlider-valueLabel{
      span{
        font-size: 14px;
        background-color: transparent;
        color: $primary-color;
        font-family: HelveticaRoman;
        margin: 12px;
      }
    }
  }
  .customized-slider{
    color: $customized-slider-one-color;
    height: 6px;
  }
  .customized-slider-rail{
    height: 6px;
  }
  .customized-slider-track{
    height: 6px;
  }
  .customized-slider-thumb{
    width: 4px;
    height: 16px;
    border-radius: 0px;
  }
}
