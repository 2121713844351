@import '../../styles/color.scss';

.sent-list-container {
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  height: calc(100vh - 80px);
  overflow-y: auto;
  background-color: $primary-color;
  .header-container {
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // position: fixed;
    // top: 0;

    .title-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 30px;
      width: 60%;
      .nomination-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        .user-icon {
          width: 18px;
          margin-left: 10px;
        }
        .nomination-text {
          font-size: 20px;
          color: $text-color-light;
          font-family: HelveticaRoman;
        }
      }
      .archive-button {
        border-radius: 20px;
        font-family: HelveticaRoman;
        line-height: 10px;
        padding: 0px 15px 5px;
      }
    }
    .back-icon {
      width: 53px;
      height: 53px;
      position: absolute;
      top: 45px;
      left: 50px;
      cursor: pointer;
    }
  }

  .recom-card-view {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pagination {
    text-align: center;
    .loader-container{
      height: 100px;
      margin-bottom: 20px;
    }
  }
}
.empty-state-container{
  height: calc(100vh - 140px - 80px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  .empty-state-text{
    font-size: 20px;
    line-height: 44px;
    font-family: HelveticaRoman;
    color: $text-color-light2;
    margin-top: 28px;
    text-align: center;
  }
}
