@import '../../styles/color.scss';
@import '../../styles/mixin.scss';

.profile-container{
  width: 90%;
  margin: 140px auto 80px auto;
  .recom-profile-header{
    .recommendation-header-container{
      width: 90%;
      right: 0;
      .content-wrapper{
        margin-top: 20px !important;
        align-items: flex-start;
        .brand-logo{
          margin-top: -10px;
        }
        .total-candidate{
          font-size: 25px !important;
          width: 400px;
          // margin-top: 30px;
        }
        .candidate-count-heading{
          font-size: 25px !important;
          font-family: HelveticaRoman;
          color: $text-color-light;
        }
        .heading-wrapper{
          .sub-heading-wrapper{
            max-width: 400px;
            flex-wrap: wrap;
            .entity{
              font-size: 25px !important;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }
  .profile-header-container{
    position: relative;
    display: flex;
    justify-content: center;
    .profile-avatar-container{
      width: 140px;
      height: 140px;
      margin: 0 auto;
      position: fixed;
      top: 70px;
      z-index: 10;
      border-radius: 50%;
      background-color: $bg-color;
      box-shadow: 0 3px 10px 0 rgba(0,0,0,0.11);
      .profile-avatar{
        width: 130px;
        height: 130px;
        border-radius: 50%;
        margin-right: 5px;
        margin-top: 5px;
        object-fit: cover;
        cursor: pointer;
      }
      .avatar-resize-icon{
        width: 30px;
        height: 30px;
        position: absolute;
        right: 15px;
        bottom: 0;
        cursor: pointer;
      }
    }
    .recom-profiles-list{
      display: flex;
      justify-content: flex-end;
      width: 90%;
      position: fixed;
      top: 100px;
      z-index: 9;
      .recom-profile{
        width: 70px;
        height: 70px;
        border-radius: 50%;
        margin-right: 5px;
        background-color: $bg-color;
        cursor: pointer;
        box-shadow: 0 3px 10px 0 rgba(0,0,0,0.11);
        img{
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin-right: 5px;
          margin-top: 5px;
          object-fit: cover;
        }
        .more-profile-count{
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin-right: 5px;
          text-align: center;
          line-height: 65px;
          color: $primary-color;
          font-family: HelveticaRoman;
          font-size: 28px;
        }
      }
    }
  }
  .profile-info-container{
    padding-top: 80px;
    .basic-info{
      text-align: center;
      padding-bottom: 60px;
      border-bottom: 1px solid $text-placeholder-color;
      .name-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        .rising-star-icon{
          width: 26px;
          height: auto;
        }
        .name{
          font-size: 45px;
          font-family: HelveticaRoman;
          color: $dusk-blue;
        }
      }
      .professional-info-wrapper{
        display: flex;
        justify-content: center;
        margin-top: 20px;
        .knowledge-network-wrapper{
          display: flex;
          align-items: center;
          margin-top: -10px;
          cursor: pointer;
          .users-icon{
            width: 16px;
            height: auto;
            margin-top: 3px;
            margin-left: 6px;
          }
          .text{
            font-size: 15px;
            color: $text-placeholder-color;
            font-family: HelveticaBold;
            text-decoration: underline;
          }
        }
        .designation{
          font-size: 15px;
          color: $text-placeholder-color;
          font-family: HelveticaRoman;
          @include truncate(300px)
        }
        .organisation{
          font-size: 15px;
          color: $text-placeholder-color;
          font-family: HelveticaBold;
          margin-right: 5px;
          text-decoration: underline;
          cursor: pointer;
          @include truncate(300px)
        }
        .futuristic{
          display: flex;
          align-items: center;
          font-family: HelveticaRoman;
          .futuristic-badge{
            margin-left: 13px;
            width: 14px;
            height: auto;
          }
          .text{
            font-size: 15px;
            margin-top: -2px;
            font-family: HelveticaRoman;
            color: $text-placeholder-color;
          }
        }
        .info-divider{
          width: 1px;
          height: 30px;
          margin: 0px 10px;
          background-color: $text-placeholder-color;
        }
      }
    }
    .description-wrapper{
      border-bottom: 1px solid $text-placeholder-color;
      position: relative;
      .pink-circle-wrapper{
        width: 40px;
        height: 70px;
        background-color: $bg-color2;
        position: absolute;
        right: -18px;
        top: -10px;
        .pink-circle{
          width: 40px;
          height: auto;
          position: absolute;
          right: 0px;
          top: 10px;
        }
      }
      .para-one{
        font-size: 28px;
        font-family: HelveticaRoman;
        color: $marine-blue;
        margin-top: 60px;
        margin-bottom: 60px;
        padding-right: 50px;
        border-right: 2pt solid $primary-color;
      }
      .para-two{
        font-size: 22px;
        font-family: HelveticaRoman;
        color: $marine-blue;
        margin-bottom: 60px;
      }
      .link-wrapper{
        display: flex;
        justify-content: center;
        .icon{
          width: 27px;
          height: 27px;
          margin-right: 10px;
        }
        .invert{
          transform: rotate(180deg)
        }
        .jump-to-link{
          font-size: 15px;
          color: $dusk-blue;
          font-family: HelveticaRoman;
          cursor: pointer;
        }
      }
    }
    .leaders-skills-wrapper{
      margin-top: 25px;
      margin-bottom: 25px;
      .section-heading{
        font-size: 20px;
        color: $reddish-grey-three;
        font-family: HelveticaRoman;
        margin-bottom: 25px;
      }
      .section-wrapper{
        margin-bottom: 20px;
        border-bottom: 1px solid $text-placeholder-color;
        &:last-child {
          border-bottom: 0px !important;
          margin-bottom: 0px;
        }
        .title{
          font-size: 15px;
          font-family: HelveticaRoman;
          color: $text-placeholder-color;
          margin-bottom: 28px;
        }
        .position-list{
          color: $light-navy-blue;
          display: flex;
          flex-wrap: wrap;
          align-items: baseline;
          justify-content: space-between;
          .position, .certificate{
            width: 32%;
            margin-left: 20px;
            margin-bottom: 30px;
            display: flex;
            &:last-child{
              margin-left: 0px;
            }
            .icon-wrapper{
              min-width: 20px;
              .position-icon::before{
                content: ' \25CF';
                font-size: 6px;
                color: $primary-color;
              }
            }
            .position-details{
              max-width: 300px;
              .duration{
                font-size: 14px;
                font-family: HelveticaRoman;
                color: $light-navy-blue;
              }
              .designation, .department{
                font-size: 20px;
                padding: 2px 0px;
                font-family: HelveticaRoman;
                color: $light-navy-blue;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .organisation{
                font-size: 20px;
                font-family: HelveticaBold;
                color: $light-navy-blue;
                @include truncate(300px)
              }
              .link{
                font-size: 15px;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
        .certificate-content{
          display: flex;
          margin-right: 0px !important;
          &:nth-child(even){
            margin-right: 30px;
          }
          &:last-child {
            border-bottom: 0px !important;
            margin-bottom: 0px;
          }
          .certificate-wrapper{
            width: 45%;
            .certificate-list{
              color: $light-navy-blue;
              align-items: baseline;
              .certificate{
                padding-bottom: 30px;
                display: flex;
                align-items: baseline;
                .icon-wrapper{
                  min-width: 20px;
                  .certificate-icon::before{
                    content: ' \25CF';
                    font-size: 6px;
                    color: $primary-color;
                  }
                }
                .certificate-details{
                  max-width: 300px;
                  .duration{
                    font-size: 14px;
                    font-family: Avenir;
                    color: $light-navy-blue;
                  }
                  .certificate-name-wrapper{
                    .degree-name{
                      font-size: 20px;
                      color: $light-navy-blue;
                      font-family: HelveticaRoman;
                    }
                    .certificate-name{
                      font-size: 20px;
                      color: $light-navy-blue;
                      font-family: HelveticaRoman;
                    }
                    .certificate-short-form{
                      font-size: 20px;
                      margin-right: 5px;
                      font-family: HelveticaBold;
                      color: $light-navy-blue;
                    }
                  }
                }
              }
            }
          }
        }
        .certificate-row-one{
          margin-bottom: 30px;
          border-bottom: 1px solid $text-placeholder-color;
        }
        .achievement-list{
          color: $light-navy-blue;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: baseline;
          .achievement{
            width: 45%;
            padding-bottom: 30px;
            display: flex;
            .icon-wrapper{
              .achievement-icon{
                width: 30px;
                height: 30px;
                color: $primary-color;
                border: 1px solid $primary-color;
                background-color: $bg-color;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            .achievement-details{
              margin-right: 17px;
              .duration{
                font-size: 14px;
                font-family: HelveticaRoman;
                color: $light-navy-blue;
              }
              .description{
                max-height: 60px;
                font-size: 20px;
                padding: 2px 0px;
                font-family: HelveticaRoman;
                color: $light-navy-blue;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .designation{
                font-size: 20px;
                font-family: HelveticaBold;
                color: $light-navy-blue;
                @include truncate(260px)
              }
              .link{
                text-decoration: underline;
                font-family: HelveticaBold;
                margin-top: 10px !important;
                font-size: 14px;
                cursor: pointer;
              }
            }
          }
        }
        .social-media-list{
          display: flex;
          align-items: center;
          margin-bottom: 40px;
          .social-media-icon{
            width: 43px;
            height: auto;
            margin-left: 40px;
          }
        }
        .news-list{
          margin-bottom: 60px;
          .news{
            display: flex;
            .news-thumbnail{
              width: 96px;
              height: 96px;
            }
            .news-description{
              margin-right: 28px;
              .news-headline{
                font-size: 20px;
                font-family: HelveticaBold;
                color: $light-navy-blue;
              }
              .hyperlink{
                font-size: 15px;
                color: $light-navy-blue;
                text-decoration: underline;
                font-family: HelveticaBold;
                cursor: pointer;
              }
            }
          }
        }
        .reference-list{
          padding: 0px;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 60px;
          .reference{
            width: 32%;
            margin-left: 30px;
            margin-bottom: 30px;
            color: $light-navy-blue;
            display: flex;
            &:last-child{
              margin-left: 0px;
            }
            .icon-wrapper{
              width: 100%;
              max-width: 20px;
              margin-top: 10px;
              .reference-icon::before{
                content: ' \25CF';
                font-size: 6px;
                color: $primary-color;
              }
            }
            .reference-details{
              max-width: 300px;
              .department{
                font-size: 20px;
                font-family: HelveticaBold;
                @include truncate(180px)
              }
              .name{
                font-size: 20px;
                margin-top: 5px;
                font-family: HelveticaBold;
                @include truncate(180px)
              }
              .position{
                font-size: 20px;
                font-family: HelveticaRoman;
              }
              .hyperlink{
                font-size: 15px;
                color: $light-navy-blue;
                text-decoration: underline;
                font-family: HelveticaBold;
                margin-top: 10px;
                cursor: pointer;
              }
            }
          }
        }
        .opinion-list{
          margin-bottom: 60px;
          display: flex;
          .opinion{
            width: 32%;
            display: flex;
            margin-left: 20px;
            &:last-child{
              margin-left: 0px;
            }
            .icon-wrapper{
              width: 100%;
              max-width: 20px;
              .opinion-icon{
                width: auto;
                height: 20px;
              }
            }
            .opinion-details{
              max-width: 300px;
              margin-right: 10px;
              color: $light-navy-blue;
              .reason{
                font-size: 15px;
                font-family: HelveticaBold;
              }
              .description{
                font-family: HelveticaRoman;
                font-size: 20px;
                overflow: hidden;
                max-height: 65px;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .hyperlink{
                font-size: 15px;
                color: $light-navy-blue;
                text-decoration: underline;
                font-family: HelveticaBold;
                margin-top: 10px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .link-wrapper{
    display: flex;
    justify-content: center;
    padding-bottom: 25px;
    .invert-icon{
      width: 27px;
      height: 27px;
      margin-right: 10px;
      transform: rotate(180deg)
    }
    .jump-to-link{
      font-size: 15px;
      color: $dusk-blue;
      font-family: HelveticaRoman;
      cursor: pointer;
    }
  }
}
.profile-details-loader-wrapper{
  .loader-wrapper-header{
    width: 100%;
    height: 140px;
    position: fixed;
    z-index: 1;
    top: 0;
    background-color: $primary-color;
  }
  .loader-container{
    margin-top: 140px;
  }
}
