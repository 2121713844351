@import '../../../styles/color.scss';

.form-container {
  .login-error {
    color: $highlight-color
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
  }
}
