@import '../../../styles/color.scss';
@import '../../../styles/mixin.scss';

.board-member-list-container{
  height: calc(100vh - 140px - 80px);
  .empty-state-container{
    height: calc(100vh - 140px - 80px);
    display: flex;
    flex: auto;
    justify-content: center;
    flex-direction: column;
    .empty-state-text{
      font-family: HelveticaRoman;
      font-size: 20px;
      line-height: 44px;
      color: $text-color-light2;
      margin-top: 28px;
      text-align: center;
    }
  }
  .board-members-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 40px;
  }
}
