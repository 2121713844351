@import '../../styles/color.scss';
@import '../../styles/mixin.scss';

.profile-card-container{
  background-color: $bg-color;
  box-shadow: 0 10px 50px 0 rgba(0,0,0,0.13);
  margin: 0 auto 60px auto;
  position: relative;
  display: flex;
  &:last-child{
    margin-bottom: 40px;
  }
  .card-left-side-content{
    width: 100%;
    position: relative;
    .recommendation-band{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 16px 22px;
      box-sizing: border-box;
      cursor: pointer;
      background-color: $text-placeholder-color;
      &.selected-profile-band-width{
        width: calc(100% - 5px);
        margin-right: 5px;
      }
      &.unselected-profile-band-width{
        width: 100%;
      }
      .recommendation-band-text{
        font-size: 17px;
        padding-bottom: 5px;
        color: $text-color-light;
        font-family: HelveticaRoman;
      }
      .recommendation-band-icon{
        width: auto;
        height: 16px;
        margin-right: 12px;
      }
    }
    .profile-details-wrapper{
      height: 255px;
      position: relative;
      padding: 60px 90px !important;
      .avatar-wrapper{
        width: 102px;
        height: 102px;
        border-radius: 50%;
        top: -50px;
        position: absolute;
        background-color: $bg-color;
        box-shadow: 0 3px 10px 0 rgba(0,0,0,0.11);
        .avatar{
          width: 92px;
          height: 92px;
          border-radius: 50%;
          margin-right: 5px;
          margin-top: 5px;
          object-fit: cover;
        }
      }
      .info-wrapper{
        .name-wrapper{
          height: 44px;
          display: flex;
          align-items: center;
          position: relative;
          .rising-star-badge{
            width: 19px;
            height: 19px;
            position: absolute;
            right: -28px;
          }
          .name{
            font-size: 30px;
            font-family: HelveticaRoman;
            color: $card-title-color;
            @include truncate(100%)
          }
        }
        .professional-info-wrapper{
          color: $text-placeholder-color;
          display: flex;
          .position{
            font-family: HelveticaRoman;
            font-size: 20px;
            @include truncate(400px)
          }
          .organisation{
            font-size: 20px;
            margin-right: 10px;
            font-family: HelveticaBold;
            @include truncate(400px)
          }
          .futuristic{
            display: flex;
            align-items: center;
            font-family: HelveticaRoman;
            .futuristic-badge{
              margin-left: 13px;
              width: 14px;
              height: auto;
            }
            .text{
              font-family: HelveticaRoman;
              font-size: 20px;
              margin-top: -5px;
            }
          }
          .info-divider{
            width: 1px;
            height: 30px;
            margin: 0px 10px;
            background-color: $text-placeholder-color;
          }
        }
        .summary-wrapper{
          width: 100%;
          display: flex;
          align-items: flex-end;
          .summary{
            width: 100%;
            font-size: 20px;
            overflow: hidden;
            color: $card-title-color;
            font-family: HelveticaRoman;
          }
          .truncate-dots{
            margin-bottom: 5px;
            margin-right: -18px;
            font-size: 20px;
            background-color: $bg-color;
          }

        }
        .nomination-reason-wrapper{
          display: flex;
          align-items: center;
          margin-top: 10px;
          cursor: pointer;
          .modify-icon{
            width: 12px;
            height: 12px;
            margin-left: 10px;
          }
          .text{
            font-size: 15px;
            font-family: HelveticaRoman;
            color: $active-input-color;
          }
        }
      }
      // .summary-wrapper{
      //   .summary{
      //     font-size: 20px;
      //     color: $card-title-color;
      //     font-family: HelveticaRoman;
      //     display: block;
      //     display: -webkit-box;
      //     max-width: 550px;
      //     -webkit-line-clamp: 2;
      //     -webkit-box-orient: vertical;
      //     overflow: hidden;
      //     text-overflow: ellipsis;
      //   }
      // }
      .buttons-wrapper{
        width: 100%;
        position: absolute;
        bottom: 60px;
        right: 0;
        margin-right: 90px;
        .contained-button{
          min-width: 180px;
          background-color: $active-input-color;
          color: $bg-color;
          border-radius: 50px;
          padding: 12px 10px 16px 10px;
          font-family: HelveticaRoman;
          font-size: 20px;
          border: 0px;
          .add-icon{
            width: 12px;
            height: 12px;
            margin-left: 10px;
            margin-top: 5px;
          }
        }
        .outline-button{
          min-width: 180px;
          border: 2px solid $active-input-color;
          color: $active-input-color;
          border-radius: 50px;
          margin-left: 20px;
          padding: 8px 10px 16px 10px;
          font-family: HelveticaRoman;
          font-size: 20px;
        }
        .red-outline-button{
          min-width: 180px;
          border: 2px solid $highlight-color;
          color: $highlight-color;
          border-radius: 50px;
          margin-left: 20px;
          padding: 8px 10px 16px 10px;
          font-family: HelveticaRoman;
          font-size: 20px;
          .remove-icon{
            width: 14px;
            height: 2px;
            margin-left: 10px;
            margin-top: 9px;
          }
        }
      }
    }
    .buttons-wrapper{
      width: 100%;
      position: absolute;
      bottom: 20px;
      .contained-button{
        min-width: 180px;
        background-color: $active-input-color;
        color: $bg-color;
        border-radius: 50px;
        padding: 12px 10px 16px 10px;
        font-family: HelveticaRoman;
        font-size: 20px;
        border: 0px;
        .add-icon{
          width: 12px;
          height: 12px;
          margin-left: 10px;
          margin-top: 5px;
        }
        .disabled-contained-button{
          background-color: $warm-gray;
        }
      }
    }

  }
  .card-right-side-content{
    position: relative;
    padding: 20px 90px !important;
    background-color: $card-right-side-color;
    .heading{
      font-size: 25px;
      color: $text-color-light;
      font-family: HelveticaRoman;
    }
    .positions-wrapper{
      padding: 20px 0px;
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      height: 120px;
    }
    .buttons-wrapper{
      position: absolute;
      bottom: 60px;
      .outline-button{
        min-width: 180px;
        border: 2px solid $bg-color;
        color: $bg-color;
        border-radius: 50px;
        margin-left: 20px;
        padding: 8px 10px 16px 10px;
        font-family: HelveticaRoman;
        font-size: 20px;
        .close-icon{
          width: 12px;
          height: 12px;
          margin-left: 10px;
          margin-top: 5px;
        }
      }
    }
  }
  .selected-profile{
    box-shadow: inset -5px 0px 0px 0px $primary-color;
  }
}
.collapsed-card{
  // width: 80%;
  width: 80vw;
}
.expanded-card{
  // width: 100%;
  width: 100vw;
  flex-direction: row-reverse;
}
.half-width{
  width: 50%;
}
