@import '../../styles/color.scss';
@import '../../styles/mixin.scss';

.media-library-container {
  width: 100%;
  max-width: 80% !important;
  height: calc(100% - 150px);
  background-color: transparent !important;
  box-shadow: none !important;
  top: 10px;
  .media-library {
    height: 100%;
    width: 100%;
    overflow: hidden;
    .close-icon {
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
    .content {
      width: 100%;
      height: 88%;
      background-color: $white-three;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      .profile-header-container{
        position: relative;
        display: flex;
        justify-content: center;
        .profile-avatar-container{
          width: 100px;
          height: 100px;
          margin: 0 auto;
          position: absolute;
          top: -50px;
          z-index: 10;
          border-radius: 50%;
          background-color: #FFFFFF;
          box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.11);
          .profile-avatar{
            width: 90px;
            height: 90px;
            border-radius: 50%;
            margin-right: 5px;
            margin-top: 5px;
            object-fit: cover;
          }
        }
      }
      .basic-info{
        text-align: center;
        margin-top: 40px;
        margin-bottom: 25px;
        .name-wrapper{
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          .rising-star-icon{
            width: 26px;
            height: auto;
          }
          .name{
            font-size: 33px;
            font-family: HelveticaRoman;
            color: $dusk-blue;
          }
        }
        .professional-info-wrapper{
          display: flex;
          justify-content: center;
          margin-top: 10px;
          .knowledge-network-wrapper{
            display: flex;
            align-items: center;
            margin-left: 10px;
            border-left: 1px solid $text-placeholder-color;
            cursor: pointer;
            .users-icon{
              width: 16px;
              height: auto;
              margin-left: 6px;
            }
            .text{
              font-size: 15px;
              color: $text-placeholder-color;
              font-family: HelveticaBold;
              text-decoration: underline;
              margin-left: 10px;
            }
          }
          .designation{
            font-size: 15px;
            color: $text-placeholder-color;
            font-family: HelveticaRoman;
            @include truncate(300px)
          }
          .organisation{
            font-size: 15px;
            color: $text-placeholder-color;
            font-family: HelveticaBold;
            margin-right: 5px;
            @include truncate(300px)
          }
          .futuristic{
            margin-right: 10px;
            display: flex;
            align-items: center;
            font-family: HelveticaRoman;
            border-right: 1px solid $text-placeholder-color;
            .futuristic-badge{
              margin-left: 13px;
              margin-right: 13px;
              width: 14px;
              height: auto;
            }
            .text{
              font-size: 15px;
              font-family: HelveticaRoman;
              color: $text-placeholder-color;
            }
          }
        }
      }
      .carousel-view {
        @include center-align;
        flex: auto;
        .slick-slider{
          width: 100%;
          .profile-image-container {
            display: flex !important;
            justify-content: center;
            outline: none;
            .profile-image {
              width: 100%;
              height: 100%;
              max-width: 240px;
              max-height: 230px;
              border-radius: 10px;
              object-fit: cover;
              @media only screen and (min-width: 1400px) {
                max-width: 300px;
                max-height: 300px;
              }
            }
          }
        }
      }
      .footer-wrapper{
        height: 80px;
        @include center-align;
        .name-info {
          font-size: 20px;
          font-family: HelveticaRoman;
          color: $dusk-blue;
          text-align: center;
        }
      }
    }
  }
}
