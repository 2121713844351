@import '../../../styles/color.scss';
@import '../../../styles/mixin.scss';

.entity-member-list-container{
  height: calc(100vh - 140px - 80px);
  .header-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 1000;
    .header-wrapper{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 53px;
      .brand-logo{
        width: 40px;
        height: 53px;
        margin-top: -30px;
      }
      .heading-wrapper {
        .small-heading{
          font-size: 15px;
          font-family: HelveticaRoman;
          color: $text-color-light;
          margin-right: 20px;
        }
        .headline{
          font-size: 25px;
          margin-right: 10px;
          font-family: HelveticaRoman;
          color: $text-color-light;
        }
      }
    }
    .back-button {
      width: 20px;
      height: 20px;
      padding-left: 40px;
      padding-top: 3px;
      cursor: pointer;
    }
  }
  .empty-state-container{
    height: calc(100vh - 140px - 80px);
    display: flex;
    flex: auto;
    justify-content: center;
    flex-direction: column;
    .empty-state-text{
      font-family: HelveticaRoman;
      font-size: 20px;
      line-height: 44px;
      color: $text-color-light2;
      margin-top: 28px;
      text-align: center;
    }
  }
  .entity-members-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 40px;
  }
}
