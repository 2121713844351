@import '../../../../styles/color.scss';

@mixin inputFieldStyle {
  padding: 14px 11px;
  font-size: 15px;
  font-family: HelveticaRoman;
  color: $primary-color;
  z-index: 10;
}

.insights-filters-container{
  height: 100vh;
  flex: auto;
  overflow-y: auto;

  .tab-one-filters, .tab-two-filters, .tab-three-filters{
    height: 100%;
    background-color: $bg-color;
    .filter-wrapper{
      padding: 20px;
      border-top: 0.5px solid $line-color;
      &:first-child{
        border-top: 0px;
      }
      .filter-name{
        font-size: 15px;
        font-family: HelveticaRoman;
        color: $text-placeholder-color;
        margin-bottom: 20px;
      }
    }
    .entity-filter-wrapper, .education-filter-wrapper, .leader-filter-wrapper, .headline-wrapper{
      padding: 20px;
      position: relative;
      .title{
        font-size: 14px;
        color: $text-placeholder-color;
        font-family: HelveticaRoman;
      }
      .search-input-wrapper{
        width: 100%;
        margin-top: 10px;
        position: relative;
        .search-input {
          margin: 0px;
          fieldset{
            top: 0px;
            background: $bg-color;
            border-radius: 25px;
            border-color: $primary-color;
            border: 1px solid $primary-color;
          }
          input {
            @include inputFieldStyle;
            text-align: center;
          }
          textarea {
            @include inputFieldStyle;
          }
        }
        .placeholder-wrapper{
          width: 100%;
          height: 1px;
          text-align: center;
          position: absolute;
          top: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: text;
          .placeholder-text{
            color: $primary-color;
            font-size: 15px;
            font-family: HelveticaRoman;
            margin-right: 10px;
            cursor: text;
          }
          .search-input-icon{
            width: 16px;
            height: 16px;
            margin-top: 7px;
            cursor: text;
          }
        }
        .search-result-container, .empty-state-container{
          width: 100%;
          height: 310px;
          border-radius: 19px;
          position: absolute;
          top: 65px;
          z-index: 100;
          box-sizing: border-box;
          background-color: $bg-color;
          box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),
          0px 8px 10px 1px rgba(0,0,0,0.14),
          0px 3px 14px 2px rgba(0,0,0,0.12);
          margin-bottom: 20px;
          .result-wrapper{
            width: 98%;
            height: 310px;
            overflow-y: auto;
            .search-option{
              padding: 10px 20px;
              font-family: HelveticaRoman;
              font-size: 17px;
              color: $card-title-color;
              cursor: pointer;
            }
          }
          .no-option-found-text{
            padding-bottom: 10px;
            font-family: HelveticaRoman;
            font-size: 18px;
            padding: 10px 20px;
          }
        }
      }
      .search-tags-wrapper{
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        .custom-chip-container{
          background-color: $active-input-color !important;
          margin-bottom: 10px;
          .chip-label{
            color: $text-color-light !important;
            font-size: 15px !important;
          }
        }
      }
      .search-results-wrapper{
        margin-top: 20px;
        height: 280px;
        overflow-y: auto;
        .search-result{
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          .label{
            font-size: 15px;
            color: $primary-color;
            font-family: HelveticaRoman;
          }
          .org-checkbox {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid $slider-label-text-color;
            position: relative;
            input[type="checkbox"] {
              display: none;
            }
            input[type="checkbox"] + span {
              display: inline-block;
              position: relative;
              top: 0px;
              left: 0px;
              width: 20px;
              height: 20px;
              position: absolute;
              vertical-align: middle;
              background: $bg-color;
              border-radius: 50%;
              cursor: pointer;
            }
            input[type="checkbox"]:checked + span {
              text-align: center;
              background: $slider-label-text-color;
              .checkmark{
                display: inline-block;
                margin-bottom: 3px;
                &:after{
                  content: '';
                  display: block;
                  width: 3px;
                  height: 7px;
                  border: solid $bg-color;
                  border-width: 0 1px 1px 0;
                  transform: rotate(45deg);
                }
              }
            }
          }
        }
      }
      .empty-state-container{
        width: 98%;
        max-height: 290px;
        overflow-y: auto;
        border-radius: 20px;
        margin: 8px;
        .no-option-found-text{
          padding: 10px 0px;
          font-family: HelveticaRoman;
          font-size: 17px;
          color: $card-title-color;
        }
      }
    }
    .tier-options-wrapper{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .radio-button-container{
        width: 48%;
        margin: 0px 0px 10px 10px;
        &:last-child, &:nth-child(2){
          margin: 0px;
        }
      }
    }
    .gender-options-wrapper{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      .radio-button-container{
        width: 48%;
        margin: 0px 0px 10px 10px;
        &:nth-child(2){
          margin: 0px;
        }
        &:last-child{
          width: 100%;
          margin: 0px;
        }
      }
    }
    .range-slider-container{
      width: initial !important;
      padding: 0px;
      margin-left: 10px;
    }
  }
}
