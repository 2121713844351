@import '../../styles/color.scss';

.advanced-filters-dialog {
  width: 100%;
  max-width: 940px !important;
  max-height: calc(100% - 250px) !important;
  border-radius: 19px !important;
  margin-top: 140px !important;
  max-width: 1150px !important;
  .filters-container {
    .row{
      padding: 25px 20px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $line-color;
      flex-direction: row-reverse;
      .filter-wrapper{
        display: flex;
        align-items: center;
        padding: 5px;
        width: 30%;
        .select-wrapper-label, .filter-label, .slider-label{
          color: $text-placeholder-color;
          font-family: HelveticaRoman;
          margin-left: 15px;
          font-size: 15px;
        }
        .slider-label{
          margin-bottom: 8px;
        }
        .name-input{
          margin: 0px;
          input{
            padding: 5px 10px;
            font-size: 15px;
            font-family: HelveticaRoman;
            color: $primary-color;
            height: 30px;
            box-sizing: border-box;
          }
          fieldset{
            border: 1px solid $primary-color;
            border-width: 1px;
            border-radius: 50px;
            color: $primary-color;
          }
          ::placeholder {
            color: $primary-color !important;
            opacity: 1 !important;
          }
        }
      }
      .radio-filter-wrapper{
        width: 140px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 5px 5px 15px;
        .icon-label{
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          .rising-star{
            width: 20px;
            height: 20px;
          }
          .select-wrapper-label{
            color: $text-placeholder-color;
            margin-right: 15px;
            font-size: 15px;
            font-family: HelveticaRoman;
            margin-bottom: 10px;
          }
        }
      }
    }
    .button-wrapper{
      padding: 35px;
      text-align: center;
      .search-button{
        font-family: HelveticaRoman;
        padding: 8px 70px 14px 70px;
        background-color: $slider-label-text-color;
        color: $text-color-light;
        border-radius: 50px;
        font-size: 19px;
      }
      .clear-button {
        margin-right: 10px;
        font-family: HelveticaRoman;
        padding: 8px 70px 14px 70px;
        border: 1px solid $slider-label-text-color;
        color: $slider-label-text-color;
        border-radius: 50px;
        font-size: 19px;
      }
    }
  }
}
