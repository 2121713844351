@import '../../../styles/color.scss';
@import '../../../styles/mixin.scss';

.position-dialog {
  .MuiPaper-root {
    border-radius: 20px;
    max-width: 80%;
    width: 80%;
  }
  .dialog-header{
    padding: 30px 20px 30px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $line-color;
    .header-title{
      font-size: 22px;
      font-family: HelveticaRoman;
      color: $text-color-light2;
    }
    .close-button{
      width: 14px;
      height: 14px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .dialog-content{
    margin-bottom: 10px;
    .position-list{
      @include reset-list;
      li.position{
        padding: 10px 20px 20px 20px;
        border-bottom: 1px solid $line-color;
        list-style: none;
        &:last-child{
          border-bottom: 0px;
        }
        .position-text{
          font-size: 20px;
          font-family: HelveticaRoman;
          color: $slider-label-text-color;
        }
      }
      .board-member-list{
        @include reset-list;
        padding: 10px 20px;
        .board-member{
          font-size: 15px;
          padding: 5px;
          font-family: HelveticaRoman;
          color: $slider-label-text-color;
        }
      }
    }
  }
}
