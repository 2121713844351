@import '../../styles/color.scss';

.expansion-panel-container{
  border-radius: 10px !important;
  margin-bottom: 12px !important;
  margin-top: 0px !important;
  &:before{
    background-color: transparent !important;
  }
  .accordion-icon{
    width: 15px;
    height: auto;
  }
  .title-wrapper{
    padding: 0px 80px 0px 10px;
    .title{
      font-size: 20px;
      color: $dusk-blue;
      font-family: HelveticaRoman;
    }
  }
  .accordion-content{
    padding: 10px 80px;
    flex-direction: column;
  }
}
