@import '../../../styles/color.scss';

.archive-insight-container {
  width: 100%;
  height: calc(100vh - 80px);
  position: fixed;
  z-index: 1000;
  top: 0;
  background-color: $primary-color;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .archive-recom {
    width: 100%;
    height: 100%;
    margin-top: 30px;
    .header-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 30px;
      .title {
        font-size: 20px;
        color: $text-color-light;
        font-family: HelveticaRoman;
      }
    }
    .pagination {
      text-align: center;
      .load-more-button {
        border-radius: 20px;
      }
    }
    .tab-view {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .tabs {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        height: 40px;
        width: 50%;
        border: 1px solid $bg-color;
        border-radius: 20px;
        margin: 20px 0px;
        .tab-button {
          border: none;
          width: 100%;
          font-family: HelveticaRoman;
          font-size: 15px;
          line-height: 26px;
          padding: 5px 15px 15px 15px;
        }
        .active-button {
          color: $dusk-blue;
          background-color: $bg-color;
          border-radius: 20px;
        }
      }
    }
    .archive-recom-list {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .back-button {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 20px;
    left: 20px;
    .back-icon {
      width: 53px;
      height: 53px;
      cursor: pointer;
    }
  }
  .empty-state-container{
    height: calc(100vh - 140px - 80px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    .empty-state-text{
      font-size: 20px;
      line-height: 44px;
      font-family: HelveticaRoman;
      color: $text-color-light2;
      margin-top: 28px;
      text-align: center;
    }
  }
}
