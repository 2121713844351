@import '../../../../styles/color.scss';
@import '../../../../styles/mixin.scss';

.footer{
  height: 90px;
  padding: 20px;
  border-top: 0.5px solid $line-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  .indicator-list{
    @include reset-list;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    .indicator{
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 1px solid $primary-color;
      margin: 0px 5px;
    }
    .active-indicator{
      background-color: $primary-color;
      border: 1px solid $primary-color;
    }
  }
  .button-wrapper{
    width: 100%;
    .tab-one-buttons, .tab-two-buttons, .tab-three-buttons{
      width: 100%;
      display: flex;
      justify-content: space-around;
      .outlined-button{
        min-width: 140px;
        padding: 3px 20px 8px;
        border: 1px solid $primary-color;
        color: $primary-color;
        border-radius: 50px;
        font-family: HelveticaRoman;
        font-size: 19px;
      }
      .contained-button{
        min-width: 140px;
        padding: 3px 20px 8px;
        background-color: $primary-color;
        color: $text-color-light;
        font-family: HelveticaRoman;
        border-radius: 50px;
        border: 1px solid $primary-color;
        font-size: 18px;
      }
      .disabled{
        background-color: $text-color-light2 !important;
        border: 0px

      }
    }
  }
}
