@import '../../styles/color.scss';

.add-position-dialog {
  .MuiPaper-root {
    width: 80%;
    max-width: 80%;
    min-width: 75%;
    min-height: 60%;
    border-radius: 20px;
  }
  .dialog-header{
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $cloudy-blue;
    .header-title{
      font-size: 22px;
      font-family: HelveticaRoman;
      color: $text-color-light;
    }
    .close-button{
      width: 14px;
      height: 14px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .dialog-content{
    margin-bottom: 30px;
    .row{
      padding: 25px 20px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $line-color;
      flex-direction: row-reverse;
      .filter-wrapper{
        display: flex;
        align-items: center;
        padding: 5px;
        width: 30%;
        .filter-label, .slider-label{
          color: $text-placeholder-color;
          font-family: HelveticaRoman;
          margin-left: 15px;
          font-size: 15px;
        }
        .MuiOutlinedInput-root {
          height: 30px;
        }
        .slider-label{
          margin-bottom: 8px;
        }
        .position-name-input{
          margin: 0px;
          input{
            padding: 10px;
            font-size: 15px;
            font-family: HelveticaRoman;
            color: $primary-color;
          }
          fieldset{
            border: 1px solid $primary-color;
            border-width: 1px;
            border-radius: 50px;
            color: $primary-color;
          }
          .hide-input{
            margin-left: 15px;
            cursor: pointer;
            img{
              width: 10px;
              height: 10px;
            }
          }
        }
        .radio-button-container{
          width: 50% !important;
          margin: 0 10px 0 0;
          &:nth-child(2){
            margin: 0 5px 0 0;
          }
          &:hover{
            background-color: $primary-color;
            border: 1px solid $primary-color;
          }
        }
        .active-btn{
          background-color: $primary-color;
          border: 1px solid $primary-color;
        }
        .range-slider-container{
          width: 85%;
        }
      }
      .radio-filter-wrapper{
        width: 140px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 5px 5px 15px;
        .icon-label{
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          .rising-star{
            width: 20px;
            height: 20px;
          }
          .filter-label{
            color: $text-placeholder-color;
            margin-right: 15px;
            font-size: 15px;
            font-family: HelveticaRoman;
            margin-bottom: 5px;
          }
        }
      }
    }
    .server-error{
      font-size: 12px;
      font-family: HelveticaRoman;
      color: $highlight-color;
      text-align: center;
    }
    .button-wrapper{
      margin-top: 30px;
      text-align: center;
      .add-button{
        font-family: HelveticaRoman;
        padding: 2px 70px 8px 70px;
        background-color: $primary-color;
        color: $text-color-light;
        border-radius: 50px;
        font-size: 19px;
      }
    }
  }
}
