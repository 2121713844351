@import '../../styles/color.scss';

.base-layout {
  min-height: 100vh;
  background-color: $bg-color2;
  display: flex;
  flex-direction: column;

  .page-container{
    flex: auto;
    overflow-y: auto;
    background-color: $bg-color2;
  }
}
