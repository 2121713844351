@import '../../styles/color.scss';

.text-input-container {
  width: 180px;

  .MuiFormControl-root {
    margin: 0;
  }

  fieldset {
    border: 1px solid $input-border-color;
    border-width: 1px !important;
    background-color: white;
  }

  .MuiOutlinedInput-root {
    padding: 10px 10px 9px;
    font-size: 13px;
    border-radius: 200px;
  }

  .MuiInputBase-input {
    z-index: 1;
    padding: 0;
    // color: $text-placeholder-color;
  }

  &.rtl {
    input {
      direction: rtl;
    }
  }
}
