@import '../../styles/color.scss';

@mixin dropdown-font-style {
  font-size: 20px;
  margin-right: 20px;
}

.recommendation-container{
  width: 100%;
  height: calc(100vh - 80px);
  position: fixed;
  z-index: 1000;
  top: 0;
  background-color: $primary-color;
  overflow-y: auto;

  .heading-container{
    text-align: center;
    .brand-logo{
      width: auto;
      height: 129px;
      margin-top: 60px;
    }
    .heading-one{
      font-size: 50px;
      font-family: NeoSans;
      color: $text-color-light;
      margin-top: 52px;
    }
    .heading-two{
      font-size: 30px;
      font-family: HelveticaRoman;
      color: $text-color-light2;
    }
  }
  .filters-container{
    margin-top: 62px;
    width: 590px;
    margin: 0 auto;
    margin-bottom: 186px;
    .row-wrapper {
      display: flex;
      flex-direction: row-reverse;
      .filters {
        width: 90%;
        .entity-filter{
          margin-top: 62px;
          display: flex;
          align-items: center;
          .search-select-input-container{
            .search-select-input{
              .MuiInputBase-root{
                .input-icon{
                  margin-left: 30px;
                  .caret-icon{
                    width: 15px;
                    height: 10px;
                  }
                }
              }
            }
            .MuiInputBase-input{
              @include dropdown-font-style;
            }
            .search-result-container{
              .result-wrapper{
                height: 205px;
              }
            }
          }
        }
        .position-filter{
          margin-top: 30px;
          display: flex;
          align-items: center;
          .search-select-input-container{
            .search-select-input{
              .MuiInputBase-root{
                .input-icon{
                  margin-left: 30px;
                  .caret-icon{
                    width: 15px;
                    height: 10px;
                  }
                }
              }
            }
            .MuiInputBase-input{
              @include dropdown-font-style;
            }
            .search-result-container{
              .result-wrapper{
                height: 205px;
              }
            }
          }
          .chip-wrapper{
            display: flex;
            flex-wrap: wrap;
          }
        }
      }
      .design-view {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 68px;
        .circle-icon {
          width: 27px;
          height: 27px;
          margin-left: 29px;
          margin-bottom: 8px;
        }
        .dot-icon {
          width: 11px;
          height: 11px;
          margin-left: 36px;
          margin-right: 6px;
          margin-top: 4px;
        }
        .dash-line {
          height: 55px;
          border: 0.5px solid $text-color-light;
          margin-left: 30px;
          border-style: dashed;
        }
      }
    }
  }
  .position-chip-container{
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    .add-position-icon{
      width: 11px;
      height: 11px;
      margin-left: 39px;
      margin-right: 6px;
      margin-top: 20px;
    }
    .chip-wrapper{
      display: flex;
      flex-wrap: wrap;
      .custom-chip-container{
        margin-bottom: 20px;
      }
      .board-member{
        font-size: 15px;
        font-family: HelveticaRoman;
        color: $text-color-light2;
        margin-bottom: 20px;
        padding: 10px 30px;
        border-left: 1px solid $text-color-light2;
        &:last-child{
          border-left: 0px;
        }
      }
    }
  }
  .add-more-link{
    display: flex;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    .add-text{
      color: $text-color-light2;
      font-size: 15px;
      font-family: HelveticaRoman;
    }
    .add-icon{
      margin-left: 10px;
      width: 12px;
      height: 12px;
    }
  }
  .button-wrapper{
    text-align: center;
    .search-button{
      margin-top: 97px !important;
      text-transform: capitalize;
      border-radius: 200px;
      background: $highlight-color;
      font-family: HelveticaRoman;
      color: white;
      padding: 5px 10px 10px 10px;
      min-width: 165px;
      font-size: 18px;
      margin-top: 30px;
      font-size: 19px;
    }
  }
}
