@import '../../../styles/color.scss';

.position-recommendation-search-container{
  height: calc(100vh - 140px - 80px);
  &::-webkit-scrollbar{
    width: 0px;
    background: transparent;
  }
  .search-input-wrapper{
    justify-content: center;
    position: fixed;
    width: 100%;
    margin-top: -35px;
    z-index: 1;
  }
  .empty-state-container{
    height: calc(100vh - 140px - 80px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    .icon-wrapper{
      width: 100%;
      text-align: center;
      .search-profile-icon{
        width: 80px;
        height: auto;
      }
    }
    .empty-state-text{
      font-size: 20px;
      line-height: 44px;
      color: $text-color-light2;
      margin-top: 28px;
      text-align: center;
      font-family: HelveticaRoman;
    }
  }
  .profile-list-container{
    padding-top: 120px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .load-more-button-wrapper{
      width: 100%;
      text-align: center;
      margin-top: -40px;
      z-index: 100;
      .outline-button{
        min-width: 160px;
        border: 2px solid $primary-color;
        color: $primary-color;
        border-radius: 50px;
        padding: 0px 10px 10px 10px;
        font-family: HelveticaRoman;
        font-size: 20px;
        margin-bottom: 40px;
      }
      .loader-container{
        margin-top: -40px;
        height: 120px !important;
        flex-direction: flex-start !important;
      }
    }
  }
  .search-tags-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .search-tag-view {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 30px;
      width: 80%;
      .search-tag {
        padding: 7px 10px 7px 10px;
        border: 1px solid $primary-color;
        border-radius: 20px;
        margin: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        &:first-child{
          margin-right: 0px;
        }
        .close-icon {
          width: 11px;
          height: 11px;
          margin-right: 10px;
          cursor: pointer;
        }
        .tag-text{
          color: $primary-color;
          font-size: 14px;
          font-family: HelveticaRoman;
          margin-top: -4px;
        }
      }
    }
  }
}
