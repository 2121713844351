@import '../../styles/color.scss';

.loader-container{
  height: calc(100vh - 140px - 80px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  .loader{
    align-self: center;

  }
  .white-loader{
    color: $bg-color;
  }
  .blue-loader{
    color: $slider-label-text-color;
  }
}
