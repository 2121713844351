@import '../../styles/color.scss';

.member-card {
  margin: 20px;
  padding: 40px;
  box-sizing: border-box;
  background-color: $bg-color;
  .card-details {
    display: flex;
    align-items: center;
    flex-direction: row;
    .basic-info{
      width: 70%;
      display: flex;
      margin-top: 30px;
      flex-direction: row;
      align-items: flex-start;
      .avatar-wrapper{
        width: 160px;
        height: 160px;
        border-radius: 50%;
        background-color: $bg-color;
        box-shadow: 0 3px 10px 0 rgba(0,0,0,0.11);
        .avatar{
          width: 150px;
          height: 150px;
          border-radius: 50%;
          margin-right: 5px;
          margin-top: 5px;
          object-fit: cover;
        }
      }
      .col-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 30px;
        .tier-details{
          display: flex;
          .tier-icon{
            width: 20px;
            height: 20px;
            line-height: 18px;
            border-radius: 50%;
            text-align: center;
            font-family: HelveticaRoman;
            color: $text-placeholder-color;
            border: 1px solid $text-placeholder-color;
            span{
              margin-top: -5px;
            }
          }
          .tier-name{
            font-size: 15px;
            line-height: 20px;
            margin-right: 10px;
            font-family: HelveticaRoman;
            color: $text-placeholder-color;
          }
        }
        .name {
          font-size: 35px;
          color: $dusk-blue;
          font-family: HelveticaRoman;
        }
        .member-position {
          font-size: 15px;
          color:$reddish-grey-three;
          font-family: HelveticaRoman;
          margin-top: 10px;
        }
      }
    }
    .description{
      width: 30%;
      height: 90px;
      overflow: hidden;
      font-size: 20px;
      color: $dusk-blue;
      font-family: HelveticaRoman;
    }
  }
  .row-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .view-button {
      width: 160px;
      height: 50px;
      font-size: 15px;
      border-radius: 25px;
      color: $active-input-color;
      font-family: HelveticaRoman;
      padding: 4px 15px 10px 15px;
      border: 1px solid $active-input-color;
    }
  }
}
