@import '../../../styles/color.scss';

.recommendation-dialog {
  .MuiPaper-root {
    border-radius: 20px;
    max-width: 80%;
    width: 80%;
  }
  .dialog-header{
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $line-color;
    .header-title{
      font-size: 22px;
      font-family: HelveticaRoman;
      color: $text-color-light2;
    }
    .close-button{
      width: 14px;
      height: 14px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .dialog-content{
    margin-bottom: 10px;
    overflow: auto;
    max-height: 300px;
    .recommendation-list{
      padding: 0px;
      margin: 0px;
      .recommendation{
        padding: 15px 20px;
        list-style: none;
        display: flex;
        justify-content: space-between;
        &.border-line {
          border-top: 1px solid $line-color;
        }
        .position, .title{
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          width: 32%;
          margin: 5px;
          .users-icon{
            width: 12px;
            height: auto;
            margin-top: 10px;
            margin-left: 10px;
          }
          .position-text, .entity-text, .title-text{
            font-size: 20px;
            font-family: HelveticaRoman;
            color: $card-title-color;
          }
        }
        .entity{
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          width: 32%;
          margin: 5px;
          .users-icon{
            width: 12px;
            height: auto;
            margin-top: 10px;
            margin-left: 10px;
          }
          .position-text, .entity-text, .title-text{
            font-size: 20px;
            font-family: HelveticaRoman;
            color: $card-title-color;
          }
        }
      }
    }
  }
}
