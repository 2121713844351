@import '../../../styles/color.scss';

.recommendation-header-container{
  width: 100%;
  height: 140px;
  z-index: 1;
  background-color: $primary-color;
  position: fixed;
  top: 0px;

  .content-wrapper{
    margin-top: 30px;
    margin-right: 50px;
    display: flex;
    align-items: center;
    .brand-logo{
      width: 40px;
      height: 53px;
      margin-top: -35px;
    }
    .heading-wrapper{
      .heading{
        font-size: 20px;
        font-family: HelveticaRoman;
        color: $text-color-light;
        margin-right: 20px;
      }
      .sub-heading-wrapper{
        height: 40px;
        display: flex;
        align-items: center;
        margin-right: 20px;
        .total-candidate{
          font-size: 29px;
          font-family: HelveticaRoman;
          color: $text-color-light;
        }
        .total-position{
          font-size: 29px;
          font-family: HelveticaRoman;
          color: $active-input-color;
          text-decoration: underline;
          margin-right: 10px;
          cursor: pointer;
        }
        .entity{
          font-size: 29px;
          font-family: HelveticaRoman;
          color: $text-color-light;
          margin-right: 10px;
        }
        .edit-button{
          border: 1px solid $highlight-color;
          border-radius: 20px;
          padding: 0px 20px;
          height: 33px;
          margin-right: 20px;
          margin-top: 10px;
          .edit-icon{
            margin-left: 6px;
            margin-right: 0px;
            img{
              width: 14px;
              height: 14px;
            }
          }
          .edit-button-text{
            font-family: HelveticaRoman;
            color: $highlight-color;
            margin-top: -5px;
            font-size: 15px;
          }
        }
      }
    }
  }
}
