

@import '../../styles/color.scss';

.sent-insight-container {
  width: 100%;
  height: calc(100vh - 80px);
  position: fixed;
  z-index: 1000;
  top: 0;
  background-color: $primary-color;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .sent-recom {
    width: 100%;
    height: 100%;
    margin-top: 30px;
    .recom-card-view {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .header-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 60px;
      .title-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 30px;
        width: 60%;
        .nomination-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          .user-icon {
            width: 18px;
            margin-left: 10px;
          }
          .nomination-text {
            font-size: 20px;
            color: $text-color-light;
            font-family: HelveticaRoman;
          }
        }
        .archive-button {
          border-radius: 20px;
          font-family: HelveticaRoman;
          line-height: 10px;
          padding: 0px 15px 5px;
        }
      }
      .back-icon {
        width: 53px;
        height: 53px;
        margin-left: 20px;
        cursor: pointer;
        position: absolute;
        left: 30px;
      }
    }
    .pagination {
      text-align: center;
      .load-more-button {
        border-radius: 20px;
      }
    }
  }
  .empty-state-container{
    height: calc(100vh - 140px - 80px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    .empty-state-text{
      font-size: 20px;
      line-height: 44px;
      color: $text-color-light2;
      font-family: HelveticaRoman;
      margin-top: 28px;
      text-align: center;
    }
  }
}
