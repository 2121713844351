@import '../../../styles/color.scss';
@import '../../../styles/mixin.scss';

.board-member-list-header-container{
  width: 100%;
  height: 140px;
  z-index: 1;
  background-color: $primary-color;
  position: fixed;
  top: 0px;
  right: 0px;
  .content-wrapper{
    margin-top: 45px;
    margin-right: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .heading-wrapper{
      display: flex;
      flex-direction: row;
      .brand-logo{
        width: 40px;
        height: 53px;
        margin-top: -15px;
      }
      .sub-heading {
        .heading{
          font-size: 15px;
          font-family: HelveticaRoman;
          color: $text-color-light;
          margin-right: 20px;
        }
        .title {
          font-size: 20px;
          font-family: HelveticaRoman;
          color: $text-color-light;
          margin-right: 20px;
        }
      }
    }
    .back-button-wrapper{
      margin-left: 40px;
      cursor: pointer;
      @include center-align;
      .back-button {
        width: 20px;
        height: 20px;
      }
    }
  }
}
