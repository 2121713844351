$primary-color: #001c47;
$secondary-color: #002257;
$secondary-color-light:#002d72;
$highlight-color: #dd4966;
$bg-color: #FFFFFF;
$bg-color2:#eceae8;
$bg-color3:#F9F9F8 ;
$text-color-light: #FFFFFF;
$text-color-light2: #a2a8bc;
$line-color: #a2a8bc;
$input-border-color: #dedede;
$text-placeholder-color: #957C6D;
$slider-label-text-color: #01193f;
$customized-slider-one-color: #51cecc;
$active-input-color: #007bb0;
$card-title-color: #172558;
$card-right-side-color: #007aae;
$dusk-blue: #264380;
$marine-blue: #002969;
$reddish-grey-three: #987f71;
$light-navy-blue: #2c4485;
$warm-gray: #939393;
$cloudy-blue: #c0c6db;
$dark-navy: #000f26;
$white-three: #e7e4e2;
$nice-blue: #1789be;
$astronaut: #25426d;
$mischka: #DDE0E5;
