@import '../../styles/color.scss';

.search-select-input-container{
  width: 100%;
  position: relative;
  .MuiFormControl-root {
    margin: 0;
  }
  fieldset {
    background-color: transparent;
  }

  .MuiOutlinedInput-root {
    // padding: 10px 10px 9px;
    font-size: 13px;
    border-radius: 200px;
    height: 30px;
    box-sizing: border-box;
  }
  &.recom-dropdown {
    .MuiOutlinedInput-root {
      height: 53px;
    }
  }

  .MuiInputBase-input {
    font-size: 15px;
    font-family: HelveticaRoman;
    z-index: 1;
    padding: 1px 0px 6px 0px;
    line-height: 34px;
    height: 34px;
  }

  &.rtl {
    input {
      direction: rtl;
    }
  }
  .search-select-input{
    .input-icon{
      z-index: 100;
      margin-right: 10px;
      margin-left: 4px;
      .caret-icon{
        width: 13px;
        height: auto;
        margin-left: 10px;
      }
    }
  }
  .search-result-container, .empty-state-container{
    width: 100%;
    height: 270px;
    border-radius: 19px;
    position: absolute;
    z-index: 100;
    box-sizing: border-box;
    background-color: $bg-color;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),
    0px 8px 10px 1px rgba(0,0,0,0.14),
    0px 3px 14px 2px rgba(0,0,0,0.12);
    margin-bottom: 20px;
    justify-content: flex-start;
    .add-link-wrapper{
      background-color: $cloudy-blue;
      display: flex;
      padding: 10px;
      border-top-left-radius: 19px;
      border-top-right-radius: 19px;
      .hyperlink{
        color: $primary-color;
        text-decoration: underline;
        font-size: 17px;
        font-family: HelveticaRoman;
        margin-right: 5px;
        cursor: pointer;
      }
      .add-info-text{
        color: $text-color-light;
        font-size: 17px;
        font-family: HelveticaRoman;
      }
    }
    .result-wrapper{
      width: 98%;
      max-height: 90%;
      margin: 10px 0px;
      overflow-y: auto;
      .search-option{
        padding: 10px 20px;
        font-family: HelveticaRoman;
        font-size: 17px;
        color: $card-title-color;
        cursor: pointer;
      }
    }
    .no-option-found-text{
      padding-bottom: 10px;
      font-family: HelveticaRoman;
      font-size: 18px;
      padding: 10px 20px;
    }
  }
}

.white-border-dropdown{
  fieldset{
    border: 1px solid $bg-color !important;
  }
  .MuiInputBase-input {
    color: $text-color-light;
  }
  input{
    &::placeholder{
      color: $text-color-light !important;
    }
  }
}
.blue-border-dropdown{
  fieldset{
    border: 1px solid $slider-label-text-color !important;
  }
  .MuiInputBase-input {
    color:$card-title-color;
  }
  ::placeholder {
    color: $primary-color !important;
    opacity: 1 !important;
  }
}
.disabled-border-dropdown{
  fieldset{
    border: 1px solid $line-color !important;
  }
  .MuiInputBase-input {
    color: $line-color;
  }
  input{
    &::placeholder{
      color: $line-color !important;
    }
  }
}

// Custom scrollbar
.result-wrapper::-webkit-scrollbar {
  width: 7px;
}
.result-wrapper::-webkit-scrollbar-track {
  background: $line-color;
  border: 4px solid transparent;
  background-clip: content-box;
}
.result-wrapper::-webkit-scrollbar-thumb {
  background: $line-color;
  border-radius: 10px;
  height: 40px;
}
