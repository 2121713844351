@import '../../styles/color.scss';
@import '../../styles/mixin.scss';

.select-input-container{
  width: 100%;
  position: relative;
  .select-input{
    background-color: transparent;
    border: 1px solid $primary-color;
    height: 34px;
    border-radius: 20px;
    padding: 0px 10px 5px;
    overflow: hidden;
    cursor: pointer;
    height: 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .select-input-text{
      line-height: 34px;
      font-size: 15px;
      font-family: HelveticaRoman;
    }
    .caret-icon{
      width: 12px;
      height: auto;
      position: absolute;
      top: 9px;
      left: 13px;
    }
  }
}

.select-input-search-result-container, .select-input-empty-state-container{
  .MuiPopover-paper{
    border-radius: 20px;
  }
  .select-input-result-wrapper, .empty-state-container{
    width: 98%;
    max-height: 290px;
    overflow-y: auto;
    border-radius: 20px;
    margin: 8px;
    .search-option{
      padding: 10px 20px;
      font-family: HelveticaRoman;
      font-size: 17px;
      color: $card-title-color;
      cursor: pointer;
    }
  }
  .no-option-found-text{
    padding-bottom: 10px;
    font-family: HelveticaRoman;
    font-size: 18px;
    padding: 10px 20px;
  }
}

.white-border-dropdown{
  fieldset{
    border: 1px solid $bg-color !important;
  }
  .MuiInputBase-input {
    color: $text-color-light;
  }
  input{
    &::placeholder{
      color: $text-color-light !important;
    }
  }
}
.blue-border-dropdown{
  fieldset{
    border: 1px solid $slider-label-text-color !important;
  }
  .MuiInputBase-input {
    color:$card-title-color;
  }
  input{
    &::placeholder {
      color: $card-title-color;
    }
  }
}

// Custom scrollbar
.select-input-result-wrapper::-webkit-scrollbar {
  width: 7px;
}
.select-input-result-wrapper::-webkit-scrollbar-track {
  background: $line-color;
  border: 4px solid transparent;
  background-clip: content-box;
}
.select-input-result-wrapper::-webkit-scrollbar-thumb {
  background: $line-color;
  border-radius: 10px;
  height: 20px;
}
