@import "../../styles/color.scss";
@import "../../styles/mixin.scss";

.tier-visualization-container {
  width: 100%;
  height: 100%;
  max-width: 450px;
  max-height: 300px;
  position: relative;
  z-index: 1;
  .tier-circle {
    position: absolute;
  }
  .profile-dot {
    background-color: #00b3f7;
    position: absolute;
    box-shadow: 5px 5px 30px 0 rgba(255, 255, 255, 0.5);
  }
  .profile-cont {
    width: 80px;
    height: 80px;
    position: absolute;
    border-radius: 40px;
    margin-left: -40px;
    margin-top: -40px;
  }
  .profile-circle-image {
    width: 80px;
    height: 80px;
  }
  .grayscale-filter {
    filter: grayscale(100%);
  }
  .profile-circle {
    width: 44px;
    height: 44px;
    position: absolute;
    left: 8px;
    top: 8px;
    border-radius: 35px;
    border: 5px solid $bg-color;
    margin: 5px;
    object-fit: cover;
    cursor: pointer;
  }
  .user-name {
    width: 120px;
    font-family: HelveticaRoman;
    font-size: 16px;
    color: $bg-color;
    margin-top: 5px;
    margin-bottom: 20px;
    margin-right: -20px;
    @include truncate(120px);
  }
  .view-profile-btn {
    width: 100px;
    margin-right: -10px;
    font-size: 10px;
    margin-bottom: 20px;
    border-radius: 30px;
    color: $highlight-color;
    padding: 2px 10px 6px 10px;
    font-family: helveticaRoman;
    background-color: $bg-color;
    border: 30px;
  }
  .empty-state-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .empty-state-text {
    font-size: 20px;
    color: $text-color-light2;
    margin-top: 38px;
    font-family: "HelveticaRoman";
  }
  &.show-visual {
    opacity: 1;
    transition: 0.5s opacity;
  }

  &.hide-visual {
    opacity: 0;
  }
}
.tier-details {
  width: 100%;
  float: left;
  height: 550px;
}
.tier-left-details {
  width: 50%;
  height: 70%;
  float: left;
}
.tier-right-details {
  width: 30%;
  float: right;
  padding-top: 100px;
}

.tier-right-details .card {
  background-color: #ffffff;
  width: 100%;
  border-radius: 8px;
  padding: 12px;
  margin: 0px 5px 10px 0px;
  display: table;
}
.card-profile {
  width: 20%;
  float: right;
}
.card-profile img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  -webkit-filter: drop-shadow(5px 5px 5px rgb(145, 143, 143));
  filter: drop-shadow(5px 5px 5px rgb(145, 143, 143));
  border: 5px solid #fff;
}
.card-details {
  width: 80%;
  float: left;
}
.card-details-name {
  font-size: 13px;
  color: #002d72;
  margin: 5px 0px;
}
.card-detail {
  font-size: 10px;
  margin-bottom: 5px;
}
