@import '../../../styles/color.scss';
@import '../../../styles/mixin.scss';

@mixin profile-avatar-container($containerWidth, $containerHeight) {
  width: $containerWidth;
  height: $containerHeight;
  margin: 0 auto;
  z-index: 10;
  border-radius: 50%;
  background-color: $bg-color;
  box-shadow: 0 3px 10px 0 rgba(0,0,0,0.11);
}

@mixin profile-avatar($avatarWidth, $avatarHeight) {
  width: $avatarWidth;
  height: $avatarHeight;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: 5px;
  object-fit: cover;
  cursor: pointer;
}

.profile-network-container{
  width: 100%;
  height: calc(100vh - 80px);
  position: fixed;
  z-index: 1000;
  top: 0;
  background-color: $primary-color;
  overflow-y: auto;
  .back-button-container{
    padding: 40px 40px 0;
    text-align: left;
    .back-btn{
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .profile-details-wrapper{
    .profile-avatar-container{
      @include profile-avatar-container(90px, 90px);
      .profile-avatar{
        @include profile-avatar(80px, 80px)
      }
    }
    .profile-name{
      font-size: 33px;
      color: $text-color-light;
      text-align: center;
      margin-top: 5px;
      font-family: HelveticaRoman;
    }
  }
  .network-details-wrapper{
    display: flex;
    .visualization-wrapper{
      width: 70%;
      margin: 60px 10%;
      .tier-visualization-container{
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .details-wrapper{
      width: 30%;
      padding: 0px 40px;
      min-height: 650px;
      margin-top: 60px;
      .tabs-wrapper{
        display: flex;
        max-width: 360px;
        justify-content: space-between;
        margin-bottom: 30px;
        .tab{
          background-color: $primary-color;
          border: 1px solid $bg-color;
          border-radius: 20px;
          min-width: 100px;
          display: flex;
          height: 40px;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .tab-text{
            color: $text-color-light;
            font-family: HelveticaRoman;
          }
        }
        .active-tab{
          background-color: $bg-color;
          .tab-text{
            color: $primary-color;
            font-family: HelveticaRoman;
          }
        }
      }
    }
    .network-profile-list-wrapper{
      max-width: 360px;
      .loader-container{
        height: 300px;
      }
      .empty-state-wrapper{
        padding: 60px 0px;
        text-align: center;
        .empty-state-text{
          font-size: 20px;
          color: $text-color-light;
          font-family: HelveticaRoman;
        }
      }
      .network-profile-card-container{
        display: flex;
        padding: 15px;
        margin-bottom: 15px;
        cursor: pointer;
        &:last-child{
          margin: 0px;
        }
        .profile-image{
          width: 30%;
          display: flex;
          align-items: center;
          .profile-avatar-container{
            @include profile-avatar-container(70px, 70px);
            .profile-avatar{
              @include profile-avatar(60px, 60px)
            }
          }
        }
        .profile-info{
          width: 70%;
          margin-right: 20px;
          .relation-wrapper{
            display: flex;
            .relation-level, .relation{
              font-size: 14px;
              font-family: HelveticaRoman;
              color: $light-navy-blue;
            }
          }
          .name{
            font-size: 20px;
            font-family: HelveticaRoman;
            color: $dusk-blue;
            text-decoration: underline;
          }
          .position{
            margin-top: 10px;
            font-size: 10px;
            font-family: HelveticaBold;
            color: $text-placeholder-color;
          }
        }
      }
    }
  }
}
