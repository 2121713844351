@import '../../../../styles/color.scss';
@import '../../../../styles/mixin.scss';

.header{
  height: 40px;
  padding: 20px;
  border-bottom: 0.5px solid $line-color;
  z-index: 2000;
  .view-text-wrapper{
    display: flex;
    align-items: center;
    .view-text{
      font-size: 24px;
      color: $text-color-light2;
      font-family: HelveticaRoman;
      @include truncate(200px);
    }
    .edit-icon{
      width: 12px;
      height: 12px;
      margin-top: 8px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .title-input-wrapper{
    width: 100%;
    position: relative;
    .title-input {
      margin: 0px;
      fieldset{
        top: 0px;
        background: $bg-color;
        border-radius: 25px;
        border-color: $primary-color;
        border: 1px solid $primary-color;
      }
      input {
        padding: 14px 11px;
        font-size: 15px;
        text-align: right;
        font-family: HelveticaRoman;
        color: $primary-color;
        line-height: 30px;
        z-index: 10;
      }
    }
  }
}
