@import '../../../styles/color.scss';
@import '../../../styles/mixin.scss';

.entity-visualization-container{
  width: 100%;
  height: 100%;
  max-width: 550px;
  max-height: 550px;
  position: relative;
  margin-top: 110px;
  .entity-circle {
    background-color: #1789be4d;
    margin: 30px;
    display: flex;
    justify-content: center;
    border: 1px solid $nice-blue;
    opacity: 1;
    .profile-count{
      width: 90px;
      height: 90px;
      position: absolute;
      margin-left: -45px;
      margin-top: -45px;
      border-radius: 25px;
      .profile-circle-image{
        width: 90px;
        height: 90px;
      }
      .profile-circle{
        width: 49px;
        height: 49px;
        position: absolute;
        left: 11px;
        top: 10px;
        border-radius: 35px;
        border: 5px solid $bg-color;
        margin: 5px;
        object-fit: cover;
        cursor: pointer;
      }
    }
    .entity-text {
      font-size: 15px;
      font-family: HelveticaRoman;
      color: $bg-color;
      align-self: center;
      @include truncate(100px);
    }
  }

  &.show-visual {
    opacity: 1;
    transition: 0.5s opacity;
  }

  &.hide-visual {
    opacity: 0;
  }
}
