@import '../../styles/color.scss';

.radio-button-container{
  width: 90px;
    height: 32px;
    margin: 0 5px 0 0;
    position: relative;
    border-radius: 25px;
    line-height: 32px;
    font-family: HelveticaRoman;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  &:hover{
    background-color: $active-input-color;
    border: 1px solid $active-input-color;
    cursor: pointer;
    .radio-button-text{
      color: $bg-color;
    }
  }
}
.radio-btn{
  background-color: $bg-color;
  border: 1px solid $slider-label-text-color;
  padding: 0px 4px 5px 4px;
  .radio-button-text{
    font-size: 15px;
    color: $slider-label-text-color;
  }
}
.active-btn{
  background-color: $active-input-color;
  border: 1px solid $active-input-color;
  padding: 0px 4px 5px 4px;
  .radio-button-text{
    font-size: 15px;
    color: $bg-color;
  }
}
