@import '../../../styles/color.scss';

.auth-layout {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: $secondary-color;
  color: $text-color-light;
  font-family: 'Avenir';
  .teo {
    height: 60px;
  }
  .content {
    margin: 100px 10px;
    max-width: 700px;
    text-align: center;
  }
  .welcome-msg {
    font-family: NeoSans;
    font-size: 50px;
    margin-top: 40px;
    font-size: 40px;
  }
  .admin-panel {
    font-size: 24px;
  }
  .logo {
    width: 70px;
  }
  .auth-input {
    background: $bg-color;
    border-radius: 200px;
    padding: 0px;
    font-size: 16px;
    outline: none;
    input {
      color: $text-placeholder-color;
      font-family: HelveticaRoman;
      font-size: 18px;
      line-height: 40px;
      padding: 10px 12px 15px 12px;
    }
    fieldset {
      border: none;
    }
  }
  .form-container {
    width: 400px;
    margin: 0 auto;
  }
  .auth-button {
    text-transform: capitalize;
    border-radius: 200px;
    background: $highlight-color;
    color: white;
    padding: 8px 40px 12px 40px;
    width: 220px;
    font-size: 18px;
    margin-top: 42px;
    font-family: HelveticaRoman;
    font-size: 20px;
  }
  .auth-button:hover {
    background-color: $highlight-color;
  }
  .auth-link {
    margin-top: 30px;
    color: $text-color-light;
    font-size: 18px;
    display: block;
  }
}
