@import '../../styles/color.scss';

.advanced-search-container{
  height: calc(100vh - 140px - 80px);
  &::-webkit-scrollbar{
    width: 0px;
    background: transparent;
  }
  .search-input-wrapper{
    justify-content: center;
    position: fixed;
    width: 100%;
    margin-top: -35px;
    z-index: 1;
  }
  .empty-state-container{
    height: calc(100vh - 140px - 80px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    .icon-wrapper{
      width: 100%;
      text-align: center;
      .search-profile-icon{
        width: 128px;
        height: auto;
      }
    }
    .empty-state-text{
      font-size: 20px;
      line-height: 44px;
      color: $text-color-light2;
      font-family: HelveticaRoman;
      margin-top: 28px;
      text-align: center;
    }
  }

  .profile-list-container{
    .outline-button{
      min-width: 160px;
      border: 1px solid $primary-color;
      color: $primary-color;
      border-radius: 50px;
      margin-left: 20px;
      padding: 0px 10px 10px 10px;
      font-family: HelveticaRoman;
      font-size: 20px;
    }
    .load-more-button-wrapper{
      width: 100%;
      text-align: center;
      margin-top: -40px;
      .loader-container{
        margin-top: -40px;
        height: 120px !important;
        flex-direction: flex-start !important;
      }
    }
  }

  .search-tags-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px 0px 0px;
    .search-tag-view {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 30px;
      width: 80%;
      .search-tag {
        padding: 7px 10px 7px 10px;
        border: 1px solid $primary-color;
        border-radius: 20px;
        margin: 0px 10px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        &:first-child{
          margin-right: 0px;
        }
        .close-icon {
          width: 11px;
          height: 11px;
          margin-right: 10px;
          cursor: pointer;
        }
        .tag-text{
          color: $primary-color;
          font-size: 14px;
          font-family: HelveticaRoman;
          margin-top: -4px;
        }
      }
    }
  }
}
