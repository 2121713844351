@import '../../../styles/color.scss';

.users-dialog {
  .MuiPaper-root {
    border-radius: 20px;
    max-width: 80%;
    width: 600px;
  }
  .dialog-header{
    padding: 30px 20px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $line-color;
    .header-title{
      font-size: 22px;
      font-family: HelveticaRoman;
      color: $text-color-light2;
    }
    .close-button{
      width: 14px;
      height: 14px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .dialog-content{
    margin-bottom: 10px;
    .user-list{
      padding: 0px;
      margin: 0px;
      max-height: 600px;
      overflow-y: auto;
      .user-text{
        font-size: 20px;
        color: $active-input-color;
        padding: 10px 20px;
        cursor: pointer;
      }
      .loader-container{
        height: 260px !important;
      }
    }
  }
}
