@import '../../../styles/color.scss';
@import '../../../styles/mixin.scss';

.insight-preview-container{
  // padding-bottom: 120px;
  .header-wrapper{
    width: 100%;
    max-height: 160px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    overflow-y: auto;
    background-color: $primary-color;
    padding: 40px 50px;
    z-index: 1000;
    .insight-badge-list{
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      height: 60px;
      .insight-badge{
        padding: 10px 25px;
        border: 1px solid $text-color-light;
        border-radius: 35px;
        margin-left: 15px;
        margin-bottom: 15px;
        cursor: pointer;
        .badge-text{
          font-size: 15px;
          font-family: HelveticaRoman;
          color: $text-color-light;
        }
      }
      .active-badge{
        background-color: $bg-color;
        .badge-text{
          color: $primary-color;
        }
      }
      .add-insight-icon-wrapper{
        .add-insight-icon{
          width: 46px;
          height: 46px;
          cursor: pointer;
        }
      }
    }
    .search-icon-wrapper{
      width: 100px;
      padding-left: 50px;
      // display: flex;
      // justify-content: flex-end;
      .search-icon{
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        background-color: $astronaut;
      }
      .search-profile-icon{
        width: 17px;
        height: 17px;
      }
    }
  }
  .content-wrapper{
    // height: calc(100vh - 160px - 80px);
    min-height: 600px;
    display: flex;
    align-items: center;
    margin-top: 160px;
    padding: 0px 50px;
    .insight-details{
      width: 40%;
      position: fixed;
      z-index: 1000;
      // margin-top: -200px;
      @media only screen and (min-width: 1400px) {
        margin-top: 0px;
      }
      .title-wrapper{
        display: flex;
        align-items: flex-start;
        .brand-logo{
          width: 42px;
          height: 53px;
          margin-top: -5px;
        }
        .insight-title{
          font-size: 30px;
          margin-right: 20px;
          font-family: NeoSans;
          color: $text-color-light;
          max-width: 400px;
        }
      }
      .buttons-wrapper{
        margin-bottom: 20px;
        .share-insight-button{
          margin-left: 20px;
          margin-top: 20px;
          min-width: 160px;
          border: 1px solid $text-color-light;
          color: $text-color-light;
          border-radius: 50px;
          padding: 0px 20px 10px 20px;
          font-family: HelveticaRoman;
          font-size: 20px;
        }
        .sent-successfully-button{
          margin-left: 20px;
          margin-top: 20px;
          min-width: 160px;
          border: 1px solid $active-input-color;
          background-color: $active-input-color;
          color: $text-color-light;
          border-radius: 50px;
          padding: 0px 20px 10px 20px;
          font-family: HelveticaRoman;
          font-size: 20px;
        }
        .icon-wrapper{
          margin-top: 20px;
          .edit-icon, .remove-icon{
            width: 50px;
            height: 50px;
            margin-left: 15px;
            cursor: pointer;
          }
        }
      }
      &.show-details {
        opacity: 1;
        transition: 0.5s opacity;
      }

      &.hide-details {
        opacity: 0;
      }
    }
    .entity-insight-visualization-view{
      width: 60%;
      margin-right: 40%;
      max-width: 800px;
      min-height: 600px;
      margin-bottom: 120px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      position: relative;
      margin-top: 60px;
    }
    .hybrid-insight-viz-view{
      .entity-visualization-container{
        margin-top: -60px;
      }
    }
    .tier-insight-visualization-view{
      width: 60%;
      margin-right: 40%;
      min-height: 600px;
      display: flex;
      justify-content: center;
    }
  }
  .pagination-wrapper{
    padding: 10px;
    position: fixed;
    bottom: 80px;
    width: 100%;
    z-index: 1000;
    background-color: $primary-color;
    .pagination{
      display: flex;
      justify-content: center;
      .active-insight-index{
        width: 90px;
        font-size: 20px;
        font-family: HelveticaRoman;
        color: $text-color-light;
        text-align: center;
      }
      .next-insight-arrow, .previous-insight-arrow{
        width: 39px;
        height: 39px;
        margin: 0px 5px;
        cursor: pointer;
      }
      .disabled-button{
        opacity: 0.5;
        pointer-events: none;
        cursor: none;
      }
    }
  }
}
