@import '../../styles/color.scss';
@import '../../styles/mixin.scss';

.photo {
  height: 32px;
  width: 32px;
}
.insight-preview-container{height: calc(100vh - 80px);}
.page-container{ height:100%; background-color: #001c47;}

.test {
  background-color: lightgrey;
  width: 300px;
  border: 15px solid green;
  padding: 50px;
  margin: 20px;
}

.tooltip {
  width: 200px; 
  font-family: HelveticaRoman; 
}
.tooltip td{padding: 5px; font-size: 22px; padding-left: 20px;}
.tooltip p{font-size: 20px; margin-top: 0px;}
.sectorscount{font-size: 30px;}
.entity-visualization-container{
  width: 100%; 
  height: 100%; 
  /*max-width: 550px;*/ max-width: 100%;
  max-height: 550px;
  position: relative;
  .entity-circle {
    /*background-color: #1789be4d; 
    margin: 45px;
    display: inline-flex;
    justify-content: center;
    border: 1px solid $nice-blue;
    opacity: 1;
    text-align: center;*/

    background-color: #1789be4d;
    margin: 35px;
    display: inline-grid;
    justify-content: center;
    border: 1px solid #1789be;
    opacity: 1;
    text-align: center;


    .profile-count{
      width: 90px;
      height: 90px;
      position: absolute;
      margin-left: -45px;
      margin-top: -45px;
      border-radius: 25px;
      .profile-circle-image{
        width: 90px;
        height: 90px;
      }
      .profile-circle{
        width: 49px;
        height: 49px;
        position: absolute;
        left: 11px;
        top: 10px;
        border-radius: 35px;
        border: 5px solid $bg-color;
        margin: 5px;
        object-fit: cover;
        cursor: pointer;
      }
    }
    .entity-text {
      font-size: 15px;
      font-family: HelveticaRoman;
      color: $bg-color;
      align-self: center;
      @include truncate(100px);
    }
  }
  

  &.show-visual {
    opacity: 1;
    transition: 0.5s opacity;
  }

  &.hide-visual {
    opacity: 0;
  }
}
.sectorsline{border: 2px solid #368acd;}
