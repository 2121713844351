@import '../../styles/color.scss';

.alert-dialog {
  .message {
    padding: 35px;
    text-align: center;
    font-size: 18px;
    color: $text-color-light2;
    font-family: HelveticaRoman;
  }
  .MuiPaper-root {
    border-radius: 20px;
    max-width: 80%;
    width: 370px;
  }
  .btn-container {
    border-top: 1px solid $input-border-color;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .action-button {
    width: 50%;
    text-transform: capitalize;
    font-size: 18px;
    padding: 12px 8px;
    border-radius: 0;
    color: $primary-color;
    font-family: HelveticaRoman;
  }
  .warning {
    color: $highlight-color;
  }
  .ok-button {
    font-size: 18px;
    padding: 12px 8px;
    width: 100%;
    border-radius: 0;
    font-family: HelveticaRoman;
  }
  .seperator {
    border-right: 1px solid $input-border-color;
    align-self: stretch;
    width: 2px;
    border-radius: 0;
    position: absolute;
    top: 0;
    right: 50%;
    height: 100%;
  }
}
