@import '../../../styles/color.scss';
@import '../../../styles/mixin.scss';

@mixin heading {
  font-size: 20px;
  color: $light-navy-blue;
  font-family: HelveticaBold;
}

@mixin text {
  font-size: 20px;
  color: $light-navy-blue;
  font-family: HelveticaRoman;
  line-height: 32px;
}

.achievement-dialog {
  .MuiPaper-root {
    border-radius: 20px;
    max-width: 90%;
  }
  .dialog-header{
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $line-color;
    .header-title{
      font-size: 22px;
      font-family: HelveticaRoman;
      color: $text-color-light2;
    }
    .close-button{
      width: 14px;
      height: 14px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .dialog-content{
    margin-bottom: 10px;
    padding: 20px 40px;
    .achievement-text{
      font-size: 15px;
      font-family: HelveticaBold;
      color: $text-placeholder-color;
      margin-bottom: 30px;
    }
    .achievement-details{
      display: flex;
      .bullet-wrapper{
        width: 40px;
        margin-left: 20px;
        .bullet{
          width: 29px;
          height: 29px;
          line-height: 29px;
          text-align: center;
          border-radius: 50%;
          border: 1px solid $light-navy-blue;
        }
      }
      .content{
        .role-details{
          margin-bottom: 20px;
          @include text;
        }
        .description-wrapper{
          .description-heading{
            width: 100%;
            @include heading;
          }
          .description-text{
            @include text;
            margin-top: 20px;
          }
        }
        .extra-details-list{
          @include reset-list;
          margin-top: 40px;
          display: flex;
          .details{
            width: 30%;
            &:nth-child(even){
              margin-right: 20px;
            }
            .detail-heading{
              @include heading;
              margin-top: 5px;
            }
            .detail-text{
              @include text;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
