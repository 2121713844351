@import "../../styles/color.scss";

.insights-container {
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  background-color: $primary-color;
  overflow-y: auto;
}

.brand-logo {
  width: 42px;
  height: 53px;
}

.sectors-title {
  color: white;
  font-family: HelveticaRoman;
}
.insight-preview-container {
  z-index: -1;
  // padding-bottom: 120px;
  .header-wrapper {
    width: 100%;
    max-height: 160px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    overflow-y: auto;
    background-color: $primary-color;
    padding: 20px 50px;
    z-index: 1000;
    .insight-badge-list {
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      height: 60px;
      .insight-badge {
        padding: 10px 25px;
        border: 1px solid $text-color-light;
        border-radius: 35px;
        margin-left: 15px;
        margin-bottom: 15px;
        cursor: pointer;
        .badge-text {
          font-size: 15px;
          font-family: HelveticaRoman;
          color: $text-color-light;
        }
      }
      .active-badge {
        background-color: $bg-color;
        .badge-text {
          color: $primary-color;
        }
      }
      .add-insight-icon-wrapper {
        .add-insight-icon {
          width: 46px;
          height: 46px;
          cursor: pointer;
        }
      }
    }
    .search-icon-wrapper {
      width: 100px;
      padding-left: 50px;
      // display: flex;
      // justify-content: flex-end;
      .search-icon {
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        background-color: $astronaut;
      }
      .search-profile-icon {
        width: 17px;
        height: 17px;
      }
    }
  }
  .content-wrapper {
    // height: calc(100vh - 160px - 80px);
    min-height: 600px;
    display: flex;
    align-items: center;
    margin-top: 160px;
    padding: 0px 50px;
    .insight-details {
      width: 40%;
      position: fixed;
      z-index: 1000;
      // margin-top: -200px;
      @media only screen and (min-width: 1400px) {
        margin-top: 0px;
      }
      .title-wrapper {
        display: flex;
        align-items: flex-start;
        .brand-logo {
          width: 42px;
          height: 53px;
          margin-top: -5px;
        }
        .insight-title {
          font-size: 30px;
          margin-right: 20px;
          font-family: NeoSans;
          color: $text-color-light;
          max-width: 400px;
        }
      }
      .buttons-wrapper {
        margin-bottom: 20px;
        .share-insight-button {
          margin-left: 20px;
          margin-top: 20px;
          min-width: 160px;
          border: 1px solid $text-color-light;
          color: $text-color-light;
          border-radius: 50px;
          padding: 0px 20px 10px 20px;
          font-family: HelveticaRoman;
          font-size: 20px;
        }
        .sent-successfully-button {
          margin-left: 20px;
          margin-top: 20px;
          min-width: 160px;
          border: 1px solid $active-input-color;
          background-color: $active-input-color;
          color: $text-color-light;
          border-radius: 50px;
          padding: 0px 20px 10px 20px;
          font-family: HelveticaRoman;
          font-size: 20px;
        }
        .icon-wrapper {
          margin-top: 20px;
          .edit-icon,
          .remove-icon {
            width: 50px;
            height: 50px;
            margin-left: 15px;
            cursor: pointer;
          }
        }
      }
      &.show-details {
        opacity: 1;
        transition: 0.5s opacity;
      }

      &.hide-details {
        opacity: 0;
      }
    }
    .entity-insight-visualization-view {
      width: 60%;
      margin-right: 40%;
      max-width: 800px;
      min-height: 600px;
      margin-bottom: 120px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      position: relative;
      margin-top: 60px;
    }
    .hybrid-insight-viz-view {
      .entity-visualization-container {
        margin-top: -60px;
      }
    }
    .tier-insight-visualization-view {
      width: 60%;
      margin-right: 40%;
      min-height: 600px;
      display: flex;
      justify-content: center;
    }
  }
  .entity-visualization-container {
    max-width: 100% !important;
  }
  .entity-visualization-container .entity-circle {
    display: inline-grid !important;
  }
  .pagination-wrapper {
    padding: 10px;
    position: fixed;
    bottom: 80px;
    width: 100%;
    z-index: 1000;
    background-color: $primary-color;
    .pagination {
      display: flex;
      justify-content: center;
      .active-insight-index {
        width: 90px;
        font-size: 20px;
        font-family: HelveticaRoman;
        color: $text-color-light;
        text-align: center;
      }
      .next-insight-arrow,
      .previous-insight-arrow {
        width: 39px;
        height: 39px;
        margin: 0px 5px;
        cursor: pointer;
      }
      .disabled-button {
        opacity: 0.5;
        pointer-events: none;
        cursor: none;
      }
    }
  }
}
.entity-view .entity-circle {
  position: static !important;
}
.sectors-header {
  width: 100%;
  position: fixed;
  top: 20px;
}
.sectors-name {
  width: 100%;
  color: #fff;
}
.sectors-header .sectors-title span {
  font-size: 12px; font-family: 'HelveticaRoman';
}
.sectors-header .sectors-title{ margin-top: 0px !important;}
.sectors-header .sectors-name span {
  font-size: 20px; font-family: 'HelveticaRoman'; padding-right: 25px;
}
.sectors-header img {
  transform: rotate(180deg);
  max-width: 10px;
  max-height: 10px; 
  margin-top: 9px;
}

.sectors-arrow {
  margin-right: 5px;
}
.sectors-details {
  width: 100%;
  float: left;
}
.sectors-left {
  width: 50%;
  float: left;
  padding-top: 30px;
}
.sectors-right {
  width: 30%;
  float: right;
  padding-top: 70px;
}

.sectors-right .card {
  background-color: #ffffff;
  width: 100%;
  border-radius: 8px;
  padding: 15px 20px;
  margin: 0px 20px 10px 0px;
  display: table; 
  max-width: 350px;
}
.card-profile {
  width: 23%;
  float: right;
}
.card-profile img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  -webkit-filter: drop-shadow(5px 5px 5px rgb(145, 143, 143));
  filter: drop-shadow(4px 3px 8px rgb(145, 143, 143));
  border: 5px solid #fff;
  margin-top: 8px;
}
.card-details {
  width: 77%;
  float: left;
}
.card-details-name {
  font-size: 17px;
  color: #0655cf;
  margin: 0px;
  font-family: 'HelveticaRoman'; text-decoration: underline;
}
.card-detail {
  font-size: 8px;
  margin: 0px;
  font-family: 'HelveticaRoman';
  color: #606060;
}
.entity-visualization-container {
  max-width: 100% !important;
}
.entity-visualization-container .entity-circle {
  display: inline-grid !important;
}

.main-sectors-title {margin-top: 0px; font-size: 40px; font-family: 'HelveticaRoman';}
.sectors-header-inner{ padding-right: 15px;}
.sectors-header-inner img{ margin-top: 8px;}
.sectors-header-inner p{ font-family: 'HelveticaRoman';}
.entity-circle .entity-text{ max-width: 125px !important; font-size: 17px !important; white-space: break-spaces !important;}