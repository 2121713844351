@import '../../../styles/color.scss';

.rec-radio-button-container{
  min-width: 100px;
  height: 32px;
  margin: 0 0 0 15px;
  position: relative;
  border-radius: 25px;
  text-align: center;
  line-height: 32px;
  margin-bottom: 15px;
  &:hover{
    background-color: $bg-color;
    cursor: pointer;
    .radio-button-text{
      color: $active-input-color;
    }
  }
}
.rec-radio-btn{
  background-color: transparent;
  border: 2px solid $text-color-light;
  padding: 0px 14px 5px 14px;
  .radio-button-text{
    font-size: 15px;
    color: $text-color-light;
    font-family: HelveticaRoman;
  }
}
.rec-active-btn{
  background-color: $bg-color;
  border: 2px solid $bg-color;
  padding: 0px 14px 5px 14px;
  .radio-button-text{
    font-size: 15px;
    color: $active-input-color;
    font-family: HelveticaRoman;
  }
}
