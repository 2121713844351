@import '../../styles/color.scss';

.search-input-container {
  width: 80% !important;
  margin: 12px auto;
  padding: 2px 4px;
  position: relative;
  border-radius: 34px !important;
  .input-wrapper{
    width: 100%;
    .search-input {
      margin: 0px;
      fieldset{
        top: 0px;
      }
      input {
        background: $bg-color;
        border-radius: 200px;
        padding: 11px 11px;
        font-size: 20px;
        text-align: center;
        font-family: HelveticaRoman;
        color: $primary-color;
      }
    }
    .placeholder-wrapper{
      width: 100%;
      height: 1px;
      text-align: center;
      position: absolute;
      top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: text;
      .placeholder-text{
        color: $text-color-light2;
        font-size: 20px;
        font-family: HelveticaRoman;
        margin-right: 10px;
        cursor: text;
      }
      .search-input-icon{
        width: 16px;
        height: 16px;
        margin-top: 10px;
        cursor: text;
      }
    }
  }
  .filter-button-wrapper{
    display: flex;
    width: 170px;
    position: absolute;
    left: 0px;
    top: 0px;
    background: white;
    border-right: 1px solid $mischka;
    border-top-left-radius: 34px;
    border-bottom-left-radius: 34px;
    &:hover{
      background-color: $mischka;
      box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),
      0px 1px 1px 0px rgba(0,0,0,0.14),
      0px 1px 3px 0px rgba(0,0,0,0.12);
    }
    .filter-button{
      min-width: 150px;
      padding: 5px 0px 4px 0px;
      &:hover{
        background-color: transparent;
      }
      .filter-button-text{
        font-size: 20px;
        font-family: HelveticaRoman;
        color: $card-title-color;
        margin-bottom: 10px;
      }
    }
    .search-button-icon {
      width: auto;
      height: 16px;
      margin-left: 15px;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}
