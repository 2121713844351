@import '../../styles/color.scss';

.custom-chip-container{
  background-color: $bg-color !important;
  padding: 5px 20px 10px 20px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  margin-left: 20px;
  .chip-label{
    font-size: 20px;
    color: $primary-color;
    font-family: HelveticaRoman;
    padding-left: 17px;
  }
  .chip-icon{
    width: 11px;
    height: 11px;
    margin-top: 4px;
    cursor: pointer;
  }
}
