@import '../../../styles/color.scss';
@import '../../../styles/mixin.scss';

.recommendation-footer-container{
  .footer-handle{
    width: 167px;
    height: 10px;
    background-color: #001c47;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 20px;
    cursor: pointer;
  }
  .open-footer{
    bottom: 375px;
  }
  .closed-footer{
    bottom: 170px;
  }
  .tab-container{
    position: fixed;
    width: 100%;
    z-index: 0;
    .app-bar{
      background-color: $primary-color;
      box-shadow: none;
      height: 78px;
      .active-tab-indicator{
        height: 0px;
      }
      .tab-scroller{
        margin-bottom: 0px !important;
      }
      .scroll-button{
        svg{
          font-size: 30px;
        }
      }
      .active-tab{
        color: $text-color-light;
        background-color: $dark-navy;
        border: 0px;
      }
      .tab{
        max-width: none;
        height: 82px;
        padding: 0px 20px;
        .MuiTab-wrapper{
          display: flex !important;
          flex-direction: row !important;
          .tab-label{
            font-size: 20px;
            color: $text-color-light;
            font-family: HelveticaRoman;
            margin-right: 20px;
            margin-bottom: 8px;
          }
          .badge{
            background-color: $highlight-color;
            color: $text-color-light;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            font-size: 10px;
            font-family: HelveticaRoman;
            margin-top: 4px;
            padding-bottom: 2px;
          }
        }
      }
    }
  }
  .expanded-footer{
    bottom: 128px;
    height: 230px;
  }
  .collapsed-footer{
    bottom: -120px;
  }
  .tab-panel-wrapper{
    height: 200px;
    background-color: $dark-navy;
    .content-wrapper{
      height: 100%;
      display: flex;
      align-items: center;
      .button-wrapper{
        width: 20%;
        min-height: 88px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: 40px;
        .contained-button{
          min-width: 200px;
          background-color: $bg-color;
          color: $primary-color;
          font-family: HelveticaRoman;
          border-radius: 20px;
          margin-bottom: 20px;
          padding: 0px 12px 7px 12px;
          font-size: 20px;
        }
        .outline-button{
          min-width: 200px;
          background-color: transparent;
          border: 1px solid $bg-color;
          color: $text-color-light;
          font-family: HelveticaRoman;
          border-radius: 20px;
          padding: 0px 12px 7px 12px;
          font-size: 20px;
        }
        .inactive-button{
          background-color: $active-input-color !important;
          color: $text-color-light !important;
        }
      }
      .loader-container{
        height: 310px;
        width: 100%;
      }
      .rec-profile-list{
        width: 80%;
        margin-right: 15px;
        display: flex;
        overflow-x: auto;
        .rec-profile-wrapper{
          max-width: 140px;
          margin: 0px 30px;
          .avatar-wrapper{
            position: relative;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background-color: $bg-color;
            box-shadow: 0 3px 10px 0 rgba(0,0,0,0.11);
            cursor: pointer;
            &:hover{
              .remove-btn{
                display: block;
              }
            }
            .remove-btn{
              width: 20px;
              height: 20px;
              position: absolute;
              display: none;
            }
            .avatar{
              width: 60px;
              height: 60px;
              border-radius: 50%;
              margin-right: 5px;
              margin-top: 5px;
              object-fit: cover;
            }
          }
          .details-wrapper{
            .name{
              font-size: 15px;
              font-family: HelveticaRoman;
              color: $text-color-light;
              @include truncate(100px);
            }
            .position{
              font-size: 15px;
              font-family: HelveticaRoman;
              color: $text-placeholder-color;
              @include truncate(100px);
            }
            .org{
              font-size: 15px;
              font-family: HelveticaRoman;
              color: $text-placeholder-color;
              margin-top: 4px;
              @include truncate(100px);
            }
          }
        }
      }
    }
  }
}
.tooltip-text{
  font-size: 15px;
  font-family: HelveticaRoman;
  color: $text-color-light;
  line-height: 20px;
}
.panel-fade-in{
  opacity:0;
  width:0;
  height:0;
  transition: width 0.3s 0.3s, height 0.3s 0.3s, opacity 0.3s;
}
.panel-fade-out{
  opacity:1;
  width:100px;
  // height:100px;
  height:78px;
  transition: width 0.2s, height 0.2s, opacity 0.2s 0.2s;
}
