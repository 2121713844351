@import '../../styles/color.scss';

.recom-card-container {
  display: flex;
  flex-direction: row;
  .swipe-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    .menu-icon {
      width: 19px;
      height: 13px;
      opacity: 0.4;
    }
    .pull-container {
      display: flex;
      flex-direction: row;
      margin-left: 20px;
      margin-top: 0px;
      .swipe-icon {
        width: 45px;
        height: 8px;
        transform: rotate(90deg);
      }
    }
  }
  .recom-card {
    min-height: 260px;
    width: 100%;
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    .recom-profiles-wrapper{
      width: 50%;
      background-color: $bg-color2;
      padding: 20px;
      align-items: center;
      display: flex;
      justify-content: center;
      .recom-profiles{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .profile-image-container {
          .profile-image {
            background-color: $bg-color2;
            width: 83px;
            height: 83px;
            border-radius: 60px;
            border: 5px solid $bg-color;
            object-fit: cover;
          }
        }
        .more-image-container {
          background-color: $bg-color;
          width: 90px;
          height: 90px;
          border-radius: 60px;
          text-align: center;
          .more-text {
            line-height: 85px;
            color: $primary-color;
            font-family: HelveticaRoman;
            font-size: 32px;
          }
        }
      }
    }
    .recom-details {
      width: 50%;
      padding: 50px;
      background-color: $bg-color;
      .title {
        color: $text-placeholder-color;
        font-size: 14px;
        font-family: HelveticaRoman;
      }
      .position {
        color: $dusk-blue;
        font-size: 30px;
        font-family: HelveticaRoman;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .date {
        color: $line-color;
        font-size: 20px;
        font-family: HelveticaRoman;
      }
    }
  }
  .recom-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    margin: 50px;
    cursor: pointer;
    .action-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      .action-text {
        color: $text-color-light;
        font-size: 20px;
        font-family: HelveticaRoman;
      }
      .edit-icon, .delete-icon {
        width: 100px;
        height: 100px;
      }
    }
  }
  .pull-container {
    width: 60px;
    margin-right: 18px;
    margin-top: 12px;
    .pull-icon {
      width: 45px;
      height: 8px;
    }
    .pull-text {
      color: $text-color-light2;
      font-size: 12px;
      font-family: HelveticaRoman;
      text-align: center;

    }
  }
}
