@import '../../../styles/color.scss';
@import '../../../styles/mixin.scss';

@mixin heading {
  font-size: 20px;
  color: $light-navy-blue;
  font-family: HelveticaBold;
}

@mixin text {
  font-size: 20px;
  color: $light-navy-blue;
  font-family: HelveticaRoman;
  line-height: 32px;
}

.reference-dialog {
  .MuiPaper-root {
    border-radius: 20px;
    width: 90%;
    max-width: 90%;
  }
  .dialog-header{
    padding: 30px 20px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $line-color;
    .header-title{
      font-size: 22px;
      font-family: HelveticaRoman;
      color: $text-color-light2;
    }
    .close-button{
      width: 14px;
      height: 14px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .dialog-content{
    margin-bottom: 10px;
    padding: 20px 40px;
    .reference-text{
      font-size: 15px;
      font-family: HelveticaBold;
      color: $text-placeholder-color;
      margin-bottom: 30px;
    }
    .reference-details{
      .reference-heading{
        @include heading;
        margin-bottom: 60px;
      }
      .details-wrapper{
        @include reset-list;
        display: flex;
        flex-wrap: wrap;
        .detail{
          width: 40%;
          margin-bottom: 40px;
          &:nth-child(even){
            margin-right: 30px;
          }
          .detail-heading{
            @include heading;
          }
          .detail-text{
            @include text();
          }
        }
      }
    }
  }
}
