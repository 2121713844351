@import '../../styles/color.scss';

.continuous-slider-container{
  width: 100%;
  background-color: white;
  padding: 10px 10px 9px;
  border-radius: 4px;
  .continuous-slider{
    color: $primary-color;
  }
  .MuiSlider-thumb{
    .MuiSlider-valueLabel{
      span{
        font-size: 14px;
        background-color: transparent;
        color: $primary-color;
        font-family: HelveticaRoman;
        margin: 0px;
      }
    }
  }
}
