@import '../../../styles/color.scss';

.insight-search-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  .back-btn{
    width: 30px;
    height: 30px;
    z-index: 100000;
    position: absolute;
    top: 50px;
    left: 40px;
    cursor: pointer;
  }
  .search-wrapper{
    width: 100%;
    padding: 40px 0px;
    background-color: $primary-color;
    position: fixed;
    text-align: center;
    z-index: 10000;
    .input-wrapper{
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      position: relative;
      .search-input {
        margin: 0px;
        fieldset{
          top: 0px;
          border: 0px;
        }
        input {
          background: $astronaut;
          border-radius: 200px;
          padding: 11px 20px 15px;
          font-size: 20px;
          text-align: right;
          font-family: HelveticaRoman;
          color: $text-color-light;
        }
      }
      .search-result-container, .empty-state-container{
        width: 100%;
        height: 310px;
        border-radius: 19px;
        position: absolute;
        top: 65px;
        z-index: 100;
        box-sizing: border-box;
        background-color: $bg-color;
        box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),
        0px 8px 10px 1px rgba(0,0,0,0.14),
        0px 3px 14px 2px rgba(0,0,0,0.12);
        margin-bottom: 20px;
        .result-wrapper{
          width: 98%;
          max-height: 290px;
          margin: 10px 0px;
          overflow-y: auto;
          .search-option{
            padding: 10px 20px;
            font-family: HelveticaRoman;
            font-size: 17px;
            color: $card-title-color;
            cursor: pointer;
            text-align: right;
          }
        }
        .no-option-found-text{
          padding-bottom: 10px;
          font-family: HelveticaRoman;
          font-size: 18px;
          padding: 10px 20px;
          text-align: right;
        }
      }
    }
    .placeholder-wrapper{
      width: 100%;
      max-width: 600px;
      height: 1px;
      text-align: center;
      position: absolute;
      top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: text;
      .placeholder-text{
        color: $text-color-light2;
        font-size: 20px;
        font-family: HelveticaRoman;
        margin-right: 10px;
        cursor: text;
      }
      .search-input-icon{
        width: 16px;
        height: 16px;
        margin-top: 10px;
        cursor: text;
      }
    }
    .clear-search-icon{
      position: absolute;
      left: 20px;
      width: 12px;
      top: 18px;
      height: 12px;
      cursor: pointer;
    }
  }
  .tier-visualization-wrapper{
    width: 100%;
    height: 85%;
    margin-top: 150px;
    margin-bottom: 100px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .empty-state-image{
      width: 500px;
      height: 500px;
    }
    .add-insight-button{
      min-width: 160px;
      border: 1px solid $bg-color;
      color: $text-color-light;
      border-radius: 50px;
      padding: 4px 10px 8px 10px;
      font-family: HelveticaRoman;
      font-size: 20px;
      position: absolute;
      margin-top: 20px;
      z-index: 500;
    }
  }
}
