@import '../../styles/color.scss';

.add-entity-dialog {
  .MuiPaper-root {
    width: 600px;
    max-width: 80%;
    min-height: 45%;
    border-radius: 20px;
  }
  .dialog-header{
    padding: 10px 20px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $cloudy-blue;
    .header-title{
      font-size: 22px;
      font-family: HelveticaRoman;
      color: $text-color-light;
    }
    .close-button{
      width: 14px;
      height: 14px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .dialog-content{
    margin: 30px;
    .row{
      width: 100%;
      display: flex;
      .filter-wrapper{
        width: 100%;
        padding: 10px 0px;
        display: flex;
        .filter-label{
          width: 140px;
          font-size: 17px;
          margin-top: 5px;
          color: $text-color-light2;
          font-family: HelveticaRoman;
        }
        .MuiOutlinedInput-root {
          height: 30px;
        }
        .entity-name-input, .entity-tag-input{
          margin: 0px;
          input{
            padding: 10px;
            font-size: 15px;
            font-family: HelveticaRoman;
            color: $primary-color;
          }
          fieldset{
            border: 1px solid $primary-color;
            border-width: 1px;
            border-radius: 50px;
            color: $primary-color;
          }
          .hide-input{
            margin-left: 15px;
            cursor: pointer;
            img{
              width: 10px;
              height: 10px;
            }
          }
        }
        .tag-filter-wrapper{
          width: 100%;
          .tags-container{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 20px;
            .tag{
              border: 1px solid $primary-color;
              padding: 5px 20px;
              position: relative;
              border-radius: 20px;
              margin-left: 10px;
              &:hover{
                .remove-tag-icon{
                  display: block;
                }
              }
              .remove-tag-icon{
                width: 15px;
                height: 15px;
                position: absolute;
                top: -7px;
                right: 0;
                cursor: pointer;
                display: none;
              }
              .tag-name{
                color: $primary-color;
                font-family: HelveticaRoman;
                font-size: 12px;
              }
            }
            .add-new-tag-container{
              display: flex;
              align-items: center;
              cursor: pointer;
              .add-icon{
                width: 10px;
                height: 10px;
                margin-left: 5px;
              }
              .add-tag-text{
                color: $active-input-color;
                font-family: HelveticaRoman;
                font-size: 12px;
              }
            }
          }
        }
        .radio-btn-list-container{
          width: 100%;
          display: flex;
          justify-content: space-between;
          .radio-button-container{
            width: 30% !important;
            margin-right: 0px;;
            &:hover{
              background-color: $primary-color;
              border: 1px solid $primary-color;
            }
          }
          .active-btn{
            background-color: $primary-color;
            border: 1px solid $primary-color;
          }
        }
      }
    }
    .server-error{
      font-size: 12px;
      font-family: HelveticaRoman;
      color: $highlight-color;
      text-align: center;
    }
    .button-wrapper{
      margin-top: 30px;
      text-align: center;
      .add-button{
        font-family: HelveticaRoman;
        padding: 2px 70px 8px 70px;
        background-color: $primary-color;
        color: $text-color-light;
        border-radius: 50px;
        font-size: 19px;
      }
    }
  }
}
