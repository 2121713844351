@import '../../../styles/color.scss';

.new-insight-container{
  width: 100%;
  height: calc(100vh - 80px);
  position: fixed;
  z-index: 1000;
  top: 0;
  background-color: $primary-color;
  overflow-y: auto;
  display: flex;
  .content-wrapper{
    width: 100%;
    padding: 80px 30px;
    display: flex;
    .visualization-wrapper{
      width: 70%;
      margin-right: 30%;
      display: flex;
      align-items: flex-start;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    .filters-wrapper{
      width: 30%;
      position: fixed;
      .filters-modal{
        max-width: 350px;
        height: calc(100vh - 240px);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        background-color: $bg-color;
      }
    }
  }
}
